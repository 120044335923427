import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const CustomLink = ({ to, children, isNew, className, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: false });
  return (
    <li className={`${isActive ? "active" : ""} ${className || ""}`} {...props}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
};
