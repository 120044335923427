import React, { useContext, useRef } from "react";
import { expandCausationColumns } from "../../../../utils";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { styles } from "../styles";
import { MultipleSelect } from "./MultipleSelected";
import { MultipleSelectedIntro } from "./MultipleSelected/MultipleSelectedIntro";
import { CausationContext } from "contexts";
import { Button } from "@mui/material";
import { PlusCircleIcon } from "assets";

export function TableCausation({
  data,
  addNewTableInfo,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused,
  selectItem,
  handleChangeMultipleSelect,
  handleSelectAllItems,
  activateOverlade,
  hasCompanyWareHouse,
  search,
  getSearchOptionsSupplier,
}) {
  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const numberItemSelected = data?.filter((item) => item.isSelected)?.length;

  const idCellRef = useRef(null);

  const { hasErpCausation, erpName } = useContext(CausationContext);
  const {
    products,
    taxIva: taxesIva,
    taxRetefuente: taxesRete,
    costCenter,
  } = search;
  return (
    <>
      {!activateOverlade && (
        <MultipleSelectedIntro
          idCellRef={idCellRef}
          activateOverlade={activateOverlade}
        />
      )}
      <div style={styles.tableContainer}>
        <MultipleSelect
          numberItemSelected={numberItemSelected}
          products={products}
          taxesIva={taxesIva}
          taxesRete={taxesRete}
          getSearchOptionsProducts={getSearchOptionsProducts}
          handleChangeMultipleSelect={handleChangeMultipleSelect}
          hasCompanyWareHouse={hasCompanyWareHouse}
        />
        <DataGrid
          autoHeight
          columns={expandCausationColumns({
            searchProductsOptions: products,
            taxesIva,
            taxesRete,
            handleOnChangeItem,
            handleDeleteItem,
            getSearchOptionsProducts,
            isCaused,
            selectItem,
            idCellRef,
            handleSelectAllItems,
            hasCompanyWareHouse,
            hasErpCausation,
            erpName,
            costCenter,
            getSearchOptionsSupplier,
          })}
          rows={data}
          hideFooter={true}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          disableColumnSort
          getRowHeight={() => "auto"}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          sx={{
            "& .MuiDataGrid-cell": {
              color: isCaused ? "rgba(0, 0, 0, 0.38)" : "#667085",
              fontSize: "14px",
              minHeight: "60px",
            },
            "& .MuiDataGrid-columnHeader": {
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
            },
            "& .MuiDataGrid-root .MuiDataGrid-main": {
              borderRadius: "8px",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "40px !important",
            },
          }}
          style={{
            borderRadius: "8px",
          }}
        />
        <div style={styles.textContainerAddRow}>
          <Button
            onClick={addNewTableInfo}
            sx={{
              color: "#5925DC",
              textTransform: "none",
              "&:hover": { backgroundColor: "transparent" },
              marginTop: "8px",
              marginBottom: "16px",
              display: "flex",
              gap: "8px",
            }}
          >
            <PlusCircleIcon width="20" height="20" />
            Agregar ítem
          </Button>
        </div>
      </div>
    </>
  );
}
