export const styles = {
  sidebar: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "80px",
    height: "100vh",
    backgroundColor: "#FFFFFF",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoContainer: {
    marginTop: "16px",
    marginBottom: "32px",
    display: "flex",
    justifyContent: "center",
  },
  menuContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      width: "100%",
    },
    "& li": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      width: "100%",
    },
    "& a": {
      width: "100%",
      padding: "8px 4px",
      borderRadius: "8px",
      color: "#344054",
      fontSize: "12px",
      fontWeight: "500",
      textDecoration: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "4px",
      textAlign: "center",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#F2F3FF",
      },
      "&.active": {
        backgroundColor: "#F5F3FF",
        color: "#6938EF",
        "& svg": {
          color: "#6938EF",
        },
        "& span": {
          color: "#6938EF",
          fontWeight: "600",
        },
      },
    },
  },
  icon: {
    fontSize: "24px",
    color: "#667085",
    marginBottom: "2px",
  },
  activeIcon: {
    fontSize: "24px",
    color: "#ffffff",
    marginBottom: "2px",
  },
  userMenuContainer: {
    marginTop: "auto",
    paddingTop: "8px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "8px",
  },
};
