import React, { useContext, useEffect } from "react";
import { UserContext } from "../../contexts";
import { ReconciliationsComingSoon } from "./components";
import { Typography } from "@mui/material";
import { styles } from "screens/ReconciliationsScreen/styles";
import { useNavigate } from "react-router-dom";

export const ReconciliationsComingSoonScreen = (props) => {
  const {
    currentCompany,
    getMe,
    isReconciliationsEnabled,
    hasSiigoCredentials,
  } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (isReconciliationsEnabled) {
      navigate("/payments", { replace: true });
    }
  }, [isReconciliationsEnabled]);

  useEffect(() => {
    if (!hasSiigoCredentials) {
      navigate("/payments", { replace: true });
    }
  }, [hasSiigoCredentials]);

  return (
    <>
      <div className="layout-container">
        <div style={styles.titleContainer}>
          <Typography sx={styles.title} noWrap>
            Conciliaciones
          </Typography>
        </div>
      </div>
      <ReconciliationsComingSoon companyName={currentCompany.name} />
    </>
  );
};
