import { TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";

export const IdentifierValue = ({ onChange, identifierValue }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: isMobile ? "100%" : "350px",
        minWidth: isMobile ? "unset" : "350px",
      }}
    >
      <label className="debts-search-form-label" htmlFor="identifierValue">
        Ingresa el número de referencia
      </label>
      <TextField
        id="identifierValue"
        size="small"
        variant="outlined"
        value={identifierValue}
        placeholder=""
        InputProps={{
          style: { fontSize: 14 },
        }}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
