export const styles = {
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "calc(100% - 80px)",
    backgroundColor: "#FFFFFF",
    zIndex: 1000,
    padding: "0 2.5%",
    height: "64px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -4px 8px -4px rgba(16, 24, 40, 0.08)",
  },
  footerElements: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  footerElementsMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footerText: {
    color: "#101828",
    fontSize: "16px",
    fontWeight: "600",
    margin: 0,
  },
  footerSubText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    margin: 0,
  },
  footerSubTextMobile: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    margin: 0,
    textAlign: "center",
  },
  bigFooterMobile: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    zIndex: 1000,
  },
  smallFooterMobile: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    zIndex: 1000,
  },
  receiversFooterElements: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dropdownPadding: {
    padding: "2px 16px 2px 16px",
  },
  noPadding: {
    padding: "0px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
  },
  dropdownItemIconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
    textAlign: "initial",
    marginTop: "16px",
  },
  dropdownItemDeleteText: {
    color: "#B42318",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  dropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
};
