import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { styles } from "./styles";

function Loading({ hasErpCausation }) {
  return (
    <div style={styles.loading}>
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>
          {hasErpCausation
            ? "Exportando información "
            : "Enviando información a Siigo"}
        </strong>
      </p>
      <p
        style={{
          color: "#475467",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 400,
        }}
      >
        <span>
          Este proceso puede demorar. Por favor no cierres esta ventana.
        </span>
      </p>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default Loading;
