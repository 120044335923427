export const styles = {
  container: {
    display: "flex",
    gap: "8px",
    padding: "8px",
    backgroundColor: "#E9E9ED",
    borderRadius: "8px",
    width: "fit-content",
    marginLeft: "2.5%",
    marginTop: "8px",
    height: "44px",
  },
  button: {
    textTransform: "none",
    color: "#667085",
    backgroundColor: "transparent",
    fontSize: "14px",
    fontWeight: 500,
    padding: "8px 16px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#F5F3FF",
      color: "#6938EF",
    },
  },
  activeButton: {
    backgroundColor: "#FFFFFF !important",
    color: "#101828 !important",
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  },
};
