import React from "react";

export const TransactionsIcon = ({
  width = 22,
  height = 22,
  color = "#101828",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <rect
      x="4.25"
      y="2.75"
      width="16.5"
      height="18.5"
      rx="3.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M11 17L11 7L8 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7L14 17L17 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
