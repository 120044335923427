import { Footer } from "../../../commons";

export const FooterHandler = ({
  selectedRows,
  totalAmount,
  openMarkAsPaidModal,
  payLater,
  openWompiModal,
  totalSelected,
  disabled,
  footerDropdown,
  deleteAction = () => {},
  approveAction = () => {},
  showApprove = true,
  showPayLater = false,
}) => {
  return (
    <Footer
      disabled={disabled || selectedRows.length === 0}
      disabledApproval={false}
      totalAmount={totalAmount}
      deleteAction={deleteAction}
      disabledMarkAsPaid={selectedRows.length < 1}
      markAsPaidAction={openMarkAsPaidModal}
      showPayLater={showPayLater}
      disablePayLater={selectedRows.length < 1 || totalAmount <= 0}
      payLaterAction={payLater}
      openWompiModal={openWompiModal}
      disableWompi={selectedRows.length < 1 || totalAmount <= 0}
      showApprove={showApprove}
      approveAction={approveAction}
      totalSelected={totalSelected}
      disabledTooltip={disabled}
      footerDropdown={footerDropdown}
    />
  );
};
