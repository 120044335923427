export const styles = {
  topNavbar: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "calc(100% - 80px)",
    height: "64px",
    backgroundColor: "#FFFFFF",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    padding: "0 1.5%",
    boxShadow: "0px 4px 8px -4px rgba(16, 24, 40, 0.08)",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  companyButton: {
    textTransform: "none",
    padding: "6px 12px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F9FAFB",
      border: "1px solid #D0D5DD",
    },
  },
  companyInfo: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    justifyContent: "flex-start",
  },
  companyLogo: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  companyName: {
    color: "#101828",
    fontSize: "12px",
    fontWeight: 600,
  },
  menu: {
    mt: "8px",
    width: "320px",
    maxHeight: "400px",
    overflow: "auto",
    borderRadius: "12px",
    boxShadow:
      "0px 8px 24px -4px rgba(16, 24, 40, 0.08), 0px 6px 12px -6px rgba(16, 24, 40, 0.04)",
    border: "1px solid #EAECF0",
  },
  menuItem: {
    padding: "12px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F9FAFB",
    },
  },
  menuItemText: {
    color: "#101828",
    fontSize: "12px",
    fontWeight: 600,
  },
  activeText: {
    color: "#027A48",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#ECFDF3",
    padding: "2px 8px",
    borderRadius: "16px",
  },
  createNewItem: {
    padding: "12px 20px",
    borderTop: "1px solid #EAECF0",
    "&:hover": {
      backgroundColor: "#F9FAFB",
    },
  },
  createNewText: {
    color: "#6938EF",
    fontSize: "12px",
    fontWeight: 600,
    marginLeft: "16px",
  },
};
