import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styles } from "./styles";

export const SubNavigation = ({ type = "contacts", className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems = {
    contacts: [
      { label: "Proveedores", path: "/contacts/suppliers" },
      { label: "Empleados", path: "/contacts/employees" },
      { label: "Servicios", path: "/contacts/services" },
      { label: "Clientes", path: "/contacts/customers" },
      { label: "Destinatarios", path: "/contacts/receivers" },
    ],
    payments: [
      { label: "Cuentas por pagar", path: "/payments/invoices" },
      { label: "Lotes", path: "/payments/pay-later" },
    ],
    payroll: [
      { label: "Pago de nómina", path: "/payroll/salaries" },
      { label: "Lotes", path: "/payroll/pay-later" },
    ],
    services: [
      { label: "Pago de servicios", path: "/services/payments" },
      { label: "Lotes", path: "/services/pay-later" },
    ],
  };

  const items = navigationItems[type];

  return (
    <Box sx={styles.container} className={className}>
      {items.map((item) => (
        <Button
          key={item.path}
          onClick={() => navigate(item.path)}
          sx={{
            ...styles.button,
            ...(location.pathname === item.path && styles.activeButton),
          }}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};
