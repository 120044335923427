import React, { useContext } from "react";
import { CausationContext } from "contexts";

export const BottomContainer = ({
  payments,
  observation,
  expandCausationCountGeneral,
}) => {
  const { hasErpCausation } = useContext(CausationContext);
  if (hasErpCausation) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "740px",
          margin: "0px auto",
        }}
      >
        {payments}
        {expandCausationCountGeneral}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "100px",
        width: "95%",
        margin: "0px auto",
        gap: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {payments}
        {observation}
      </div>
      {expandCausationCountGeneral}
    </div>
  );
};
