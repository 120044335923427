export const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#F6F7F9",
  },
  mainContent: {
    flexGrow: 1,
    marginLeft: "80px",
    marginTop: "64px", // Altura de la TopNavbar
    transition: "margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    "@media (max-width: 481px)": {
      marginLeft: 0,
      marginTop: 0,
      padding: "16px",
    },
  },
};
