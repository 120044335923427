import React from "react";
import { ModalWarningIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { styles } from "./styles";
import * as CausationService from "../../../services/api/causation";

function ErrorCausation({ onClose, modalData, id }) {
  const handleMarkAsCaused = () => {
    CausationService.updateInvoices([id]);
    onClose();
  };

  return (
    <div
      style={{ maxWidth: "400px", display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", alignSelf: "center" }}>
        <ModalWarningIcon />
      </div>
      <p style={styles.errorModalTitle}>{modalData.title}</p>
      <p style={styles.errorModalText}>{modalData.description}</p>
      <div style={styles.buttonGroup}>
        {modalData.twoButtons ? (
          <>
            <SecondaryButton
              text="Salir"
              action={onClose}
              style={{ width: "100%" }}
            />
            <PrimaryButton
              text="Marcar como causada"
              action={handleMarkAsCaused}
              style={{ width: "100%" }}
            />
          </>
        ) : (
          <PrimaryButton
            text="Entendido"
            action={onClose}
            style={{ width: "50%" }}
          />
        )}
      </div>
    </div>
  );
}

export default ErrorCausation;
