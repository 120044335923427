import React from "react";
import { Box, Modal, RadioGroup, Stack, Typography } from "@mui/material";
import { DateDropdown, StyledFormControlLabel, CustomRadio } from "./";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { addYears } from "date-fns";

export const BankMovementsFiltersModal = ({
  visible,
  setIsFiltersModalVisible,
  date,
  setDate,
  selectedDate,
  setSelectedDate,
  setType,
  amountSignSelected,
  setAmountSignSelected,
  setPageIndex,
}) => {
  const handleConfirm = () => {
    setIsFiltersModalVisible(false);
    setPageIndex(1);
  };

  const handleClear = () => {
    setDate([
      {
        startDate: addYears(new Date(), -1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setSelectedDate([{ startDate: "all", endDate: "all" }]);
    setType(null);
    setAmountSignSelected("all");
    setPageIndex(1);
    setIsFiltersModalVisible(false);
  };

  return (
    <Modal
      open={visible}
      onClose={() => setIsFiltersModalVisible(false)}
      centered
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" mb={2}>
          Filtros
        </Typography>

        <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: 1 }}>
          Período
        </Typography>
        <DateDropdown
          date={date}
          setDate={setDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          defaultText="Elige aquí"
        />

        <Typography sx={{ fontSize: "14px", fontWeight: "600", mt: 2, mb: 1 }}>
          Tipo de movimiento
        </Typography>
        <RadioGroup
          value={amountSignSelected}
          onChange={(e) => setAmountSignSelected(e.target.value)}
        >
          <StyledFormControlLabel
            value="positive"
            control={<CustomRadio />}
            label="Ingreso"
          />
          <StyledFormControlLabel
            value="negative"
            control={<CustomRadio />}
            label="Egreso"
          />
        </RadioGroup>

        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={4}>
          <SecondaryButton text="Limpiar" action={handleClear} width="120px" />
          <PrimaryButton text="Aplicar" action={handleConfirm} width="120px" />
        </Stack>
      </Box>
    </Modal>
  );
};
