import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { NewNavigationImage, NewTxPanel } from "assets";

export const NewNavegationModal = ({ visible, handleConfirm, handleSkip }) => {
  return (
    <Modal show={visible} onHide={handleSkip} centered>
      <Modal.Body>
        <Box style={styles.container}>
          <div style={styles.columnContainer}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#111322",
                backgroundColor: "#EBE9FE",
                padding: "4px 12px",
                borderRadius: "16px",
                marginBottom: "12px",
                width: "fit-content",
              }}
            >
              ¡Nuevo!
            </p>
            <p style={styles.title}>Barra de navegación lateral</p>
            <p
              style={{
                ...styles.subtitle,
                maxWidth: "298px",
                textAlign: "start",
                alignSelf: "flex-start",
              }}
            >
              Hemos actualizado el diseño para que sea más fácil y rápido
              acceder a tus módulos.
            </p>
            <p
              style={{
                ...styles.subtitle,
                maxWidth: "298px",
                textAlign: "start",
                alignSelf: "flex-start",
              }}
            >
              Aprende cómo usarlo con nuestra guía virtual.
            </p>
            <div style={styles.row}>
              <PrimaryButton
                text={"Explorar"}
                action={handleConfirm}
                width="140px"
              />
              <SecondaryButton
                text={"Omitir"}
                action={handleSkip}
                width="140px"
              />
            </div>
          </div>
          <div style={styles.columnContainer}>
            <NewNavigationImage />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
