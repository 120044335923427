const baseStatusesFilter = {
    all: {
        value: 'all',
        label: 'Todas',
    },
    pending: {
        value: 'pending',
        label: 'Por pagar',
    },
    paid: {
        value: 'paid',
        label: 'Pagadas',
    },
    expired: {
        value: 'expired',
        label: 'Vencidas',
    },
};

export const servicesTableStatusFilters = () => {
    return [
        baseStatusesFilter.all,
        baseStatusesFilter.pending,
        baseStatusesFilter.paid,
        baseStatusesFilter.expired,
    ];
};
