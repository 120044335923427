import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ContactsScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/contacts/suppliers");
  });
  return null;
};
