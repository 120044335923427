import React from "react";

export const ToChargeIcon = ({
  width = 22,
  height = 22,
  color = "#101828",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <path
      d="M10.4788 6.04385V17.9555"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.351 8.2114H9.04306C7.92946 8.2114 7.03565 9.06026 7.03565 10.1024C7.03565 11.1445 7.93679 11.9933 9.04306 11.9933H11.915C13.0286 11.9933 13.9224 12.8422 13.9224 13.8843C13.9224 14.9333 13.0213 15.7752 11.915 15.7752H7.02832"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8752 18.7702C18.8752 20.5507 17.3367 22 15.4465 22H5.51198C3.62178 22 2.08325 20.5507 2.08325 18.7702V5.22981C2.08325 3.44928 3.62178 2 5.51198 2H15.4465C17.3367 2 18.8752 3.44928 18.8752 5.22981"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9166 12.4275L17.3999 12.4275"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1619 15.0301L17.3999 12.4283L20.1619 9.83337"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
