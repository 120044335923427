import React from "react";

export const NewCheckIcon = ({ fill = "#E0F2FE", stroke = "#0BA5EC" }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <rect x="0.5" width="24" height="24" rx="12" fill={fill} />
    <path
      d="M17.9432 7.74994L18.0177 7.67804C18.1718 7.57224 18.2893 7.62739 18.32 7.65562C18.337 7.67129 18.3485 7.69068 18.3459 7.72897C18.3429 7.77369 18.3149 7.89055 18.1486 8.06051L18.1352 8.07418L18.1229 8.08883L18.506 8.41016C18.1229 8.08883 18.1229 8.08886 18.1229 8.08892L18.1226 8.0892L18.1217 8.09032L18.118 8.09477L18.1032 8.11235L18.0457 8.18095L17.827 8.44173L17.0437 9.37601C16.394 10.1512 15.5239 11.1898 14.6426 12.243C12.8874 14.3405 11.0678 16.5198 10.8791 16.7661L10.867 16.7819L10.8562 16.7985C10.73 16.9937 10.4994 17.1202 10.256 17.1202C9.99456 17.1202 9.77524 16.9955 9.64854 16.8027L7.40533 13.1538C7.30283 12.9746 7.34157 12.7487 7.52872 12.6056C7.74652 12.4571 8.0211 12.4809 8.18368 12.6254L10.071 14.6418L10.4179 15.0125L10.7832 14.6599L17.9432 7.74994Z"
      fill="#0BA5EC"
      stroke={stroke}
    />
  </svg>
);
