import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { NumericFormat } from "react-number-format";
import { regexNumberWIthDecimal } from "utils";

export const InputEditNumberRow = ({
  valueRow,
  isCaused = false,
  onChange,
  formatMoney = false,
  ...props
}) => {
  const { id, field } = props;
  const apiRef = useGridApiContext();
  const [value, setValue] = useState(valueRow);
  const inputRef = useRef(null);

  const handleOnChange = (value) => {
    if (!regexNumberWIthDecimal(value)) return;

    apiRef.current.setEditCellValue({
      id,
      field,
      value,
      debounceMs: 200,
    });
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <TextField
      tabIndex="0"
      inputRef={inputRef}
      value={value}
      type="text"
      disabled={isCaused}
      onChange={(e) => handleOnChange(e.target.value)}
      style={{
        width: "100%",
        height: "100%",
      }}
      sx={{
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          border: "none",
          background: "transparent",
          textOverflow: "ellipsis",
          outline: "none",
          height: "100%",
          fontSize: "13px",
          "& fieldset": {
            border: "none",
          },
        },
        ".MuiInputBase-input.MuiOutlinedInput-input": {
          fontSize: "13px",
          padding: "0px 10px",
        },
      }}
      onKeyDown={(event) => {
        if (
          event.key === " " ||
          event.key === "ArrowRight" ||
          event.key === "ArrowLeft"
        ) {
          event.stopPropagation();
        }
      }}
      InputProps={{
        inputComponent: MoneyFormatCustom,
      }}
    />
  );
};

const MoneyFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, formatMoney, ...other } = props;
  return (
    <NumericFormat
      {...other}
      style={{ fontSize: "13px", padding: "0px 10px" }}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={formatMoney ? "$" : ""}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
});
