import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteIconContainer,
  DeleteItemModal,
  RenderIfPermissionEnabled,
  Table,
  TableEmptyState,
  TableSkeleton,
  Tooltip,
  UploadFileModal,
} from "../../commons";
import { customersColumns, receiversColumns, filterOptions } from "../../utils";
import { styles } from "./styles";
import {
  GetTotalCustomers,
  GetTotalReceivers,
  SearchCustomers,
  SearchReceivers,
} from "../../services";
import { CollectionsContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import { CollectionsAgendaFilter, NewCustomersDropdown } from "./components";
import { EditIcon, CopyUrlIcon, SearchIcon } from "../../assets";
import { useMediaQuery } from "react-responsive";
import CsvHandler from "../../commons/modals/UploadFileModal/CsvHandler";
import { toast } from "react-toastify";
import { permissions, toastOptions } from "../../constants";
import { useChat } from "../../hooks";
import { ReceiverDetails } from "commons/modals/ReceiverDetails";
import { CustomerDetails } from "commons/modals/CustomerDetails";
import { SubNavigation } from "commons/Layout/components/SubNavigation";

export const CustomersScreen = ({ defaultType = "customer" }) => {
  const customersCols = useMemo(() => customersColumns, []);
  const receiversCols = useMemo(() => receiversColumns, []);
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotals] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [deleteSupplierModalIsVisible, setDeleteSupplierModalIsVisible] =
    useState(false);
  const [customerToDelete, setCustomerToDelete] = useState();
  const {
    customers,
    getCustomers,
    deleteCustomer,
    customersScreen,
    receivers,
    getReceivers,
    deleteReceiver,
    mainReceiver,
    getMainReceiver,
  } = useContext(CollectionsContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [receiverDrawerIsOpen, setReceiverDrawerIsOpen] = useState(false);
  const [uploadFileModalIsVisible, setUploadFileModalIsVisible] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const [typeSelected, setTypeSelected] = useState(defaultType);
  const [deleteTitle, setDeleteTitle] = useState(
    "¿Quieres borrar este cliente?"
  );
  const [entity, setEntity] = useState(defaultType);
  const location = useLocation();
  const customersCsvButton = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  useEffect(() => {
    setTypeSelected(defaultType);
    setEntity(defaultType);
  }, [defaultType]);

  useChat();

  const customerIdSelected = customersScreen?.customerIdSelected?.value;
  const setCustomerIdSelected = customersScreen?.customerIdSelected?.setter;

  useEffect(() => {
    getMainReceiver();
    GetTotalCustomers({
      customerId: customerIdSelected,
    }).then((res) => {
      setTotals(res);
    });
    location.state &&
      location.state.openCreateModal &&
      openEmptyCustomerDrawer();
    location.state &&
      location.state.openEditModal &&
      openCustomerDrawer(location.state.providerId);
  }, []);

  useEffect(() => {
    if (typeSelected === "customer") {
      GetTotalCustomers({
        customerId: customerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este cliente?");
      setEntity("customer");
    } else {
      GetTotalReceivers({
        receiverId: customerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este destinatario?");
      setEntity("receiver");
    }
  }, [typeSelected]);

  const getSearchOptions = (query) => {
    typeSelected === "customer"
      ? SearchCustomers(query).then((res) => {
          setSearchOptions(res);
        })
      : SearchReceivers(query).then((res) => {
          setSearchOptions(res);
        });
  };

  const openDeleteCustomerModal = (id) => {
    setCustomerToDelete(id);
    setDeleteSupplierModalIsVisible(true);
  };

  useEffect(() => {
    if (typeSelected === "customer") {
      getCustomers(1, customerIdSelected);
      GetTotalCustomers({
        customerId: customerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este cliente?");
      setEntity("customer");
    } else {
      getReceivers(1, customerIdSelected);
      GetTotalReceivers({
        receiverId: customerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este destinatario?");
      setEntity("receiver");
    }
  }, [customerIdSelected, typeSelected]);

  useEffect(() => {
    if (typeSelected === "customer") {
      getCustomers(pageIndex, customerIdSelected);
    } else {
      getReceivers(pageIndex, customerIdSelected);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const removeCustomer = (id) => {
    deleteCustomer(id);
    setDeleteSupplierModalIsVisible(false);
  };

  const removeReceiver = (id) => {
    deleteReceiver(id);
    setDeleteSupplierModalIsVisible(false);
  };

  const openCustomerDrawer = (id) => {
    setDrawerIsOpen(true);
    setSelectedCustomer(id);
  };

  const openReceiverDrawer = (id) => {
    setReceiverDrawerIsOpen(true);
    setSelectedReceiver(id);
  };

  const openEmptyCustomerDrawer = () => {
    setEntity("customer");
    setDrawerIsOpen(true);
  };

  const openEmptyReceiverDrawer = () => {
    setEntity("receiver");
    setReceiverDrawerIsOpen(true);
  };

  const openUploadFileModal = () => {
    customersCsvButton.current.click();
  };

  const closeUploadFileModal = () => {
    getCustomers(1, "all");
    setUploadFileModalIsVisible(false);
  };

  const handleSearchChange = (customer) => {
    if (customer) {
      setCustomerIdSelected(customer.id);
      customersScreen?.customerFilter?.setter(customer);
    } else {
      setCustomerIdSelected("all");
      customersScreen?.customerFilter?.setter({});
    }
    setPageIndex(1);
  };

  const copyCustomerUrl = (hash) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/pay?hash=${hash}`
    );
    toast.success(
      "El link al portal del cliente fue copiado a tu portapapeles con éxito.",
      toastOptions
    );
  };

  const renderCustomersAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14, backgroundColor: "#FFFFFF" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar cliente"}
          />
        )}
        value={
          customersScreen?.customerFilter?.value || {
            name: "",
          }
        }
      />
    );
  };

  const renderReceiversAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14, backgroundColor: "#FFFFFF" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar destinatario"}
          />
        )}
        value={
          customersScreen?.customerFilter?.value || {
            name: "",
          }
        }
      />
    );
  };

  const renderButtons = (row) => {
    return (
      <>
        <RenderIfPermissionEnabled permission={permissions.COLLECTIONS_UPDATE}>
          <Tooltip title="Editar">
            <IconButton
              onClick={() =>
                typeSelected === "customer"
                  ? openCustomerDrawer(row.original.id)
                  : openReceiverDrawer(row.original.id)
              }
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </RenderIfPermissionEnabled>
        {typeSelected === "customer" && (
          <RenderIfPermissionEnabled
            permission={permissions.COLLECTIONS_UPDATE}
          >
            <Tooltip title="Copiar link al portal del cliente">
              <IconButton onClick={() => copyCustomerUrl(row.original.hash)}>
                <CopyUrlIcon />
              </IconButton>
            </Tooltip>
          </RenderIfPermissionEnabled>
        )}
        <RenderIfPermissionEnabled permission={permissions.COLLECTIONS_DELETE}>
          <DeleteIconContainer
            itemId={row.original.id}
            openDeleteItemModal={openDeleteCustomerModal}
          />
        </RenderIfPermissionEnabled>
      </>
    );
  };

  const renderRowEndButtons = (row) => {
    if (isMobile) {
      return <>{renderButtons(row)}</>;
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>{renderButtons(row)}</td>
      );
    }
  };

  return (
    <>
      <CsvHandler
        entity="supplier"
        licenseKey={process.env.REACT_APP_CSVBOX_SUPPLIERS_LICENSE_KEY}
        buttonRef={customersCsvButton}
        setTotals={setTotals}
      />
      <DeleteItemModal
        itemId={customerToDelete}
        action={typeSelected === "customer" ? removeCustomer : removeReceiver}
        handleClose={() => setDeleteSupplierModalIsVisible(false)}
        title={deleteTitle}
        subtitle={"Si lo eliminas, no aparecerá más en Payana"}
        visible={deleteSupplierModalIsVisible}
      />
      <UploadFileModal
        entity={"customers"}
        type={"SupplierCSV"}
        visible={uploadFileModalIsVisible}
        handleClose={closeUploadFileModal}
      />
      <CustomerDetails
        id={selectedCustomer}
        isOpen={drawerIsOpen}
        handleClose={() => {
          setSelectedCustomer(null);
          setDrawerIsOpen(false);
        }}
        needRedirectionCreate={
          location.state ? location.state.openCreateModal : false
        }
        needRedirectionEdit={
          location.state ? location.state.openEditModal : false
        }
      />
      <ReceiverDetails
        id={selectedReceiver}
        isOpen={!!receiverDrawerIsOpen}
        handleClose={() => {
          setSelectedReceiver(null);
          setReceiverDrawerIsOpen(false);
        }}
        mainReceiver={mainReceiver}
      />
      <div>
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Agenda
            </Typography>
            <RenderIfPermissionEnabled
              permission={permissions.COLLECTIONS_CREATE}
            >
              <NewCustomersDropdown
                openUploadFileModal={openUploadFileModal}
                openEmptyCustomerDrawer={openEmptyCustomerDrawer}
                openEmptyReceiverDrawer={openEmptyReceiverDrawer}
              />
            </RenderIfPermissionEnabled>
          </div>
          <Box sx={{ mb: 3 }}>
            <SubNavigation type="contacts" />
          </Box>
          {typeSelected === "customer" ? (
            customers.length < 1 ? (
              <TableEmptyState
                title={"Tu empresa aún no tiene clientes cargados"}
                subtitle={
                  "Aquí se mostrarán los clientes de tus facturas por cobrar."
                }
              />
            ) : customers.length >= 1 ? (
              <Table
                columns={customersCols}
                data={customers}
                editIcon
                editAction={openCustomerDrawer}
                setPageIndex={setPageIndex}
                checkboxVisible={false}
                pageCount={totalPages}
                deleteIcon
                openDeleteItemModal={openDeleteCustomerModal}
                section="customers"
                supplier={customersScreen?.customerFilter?.value}
                setSupplier={customersScreen?.scustomerFilter?.setter}
                renderAutocomplete={renderCustomersAutocomplete}
                renderRowEndButtons={renderRowEndButtons}
                pageNumber={pageIndex}
              />
            ) : (
              <TableSkeleton />
            )
          ) : receivers.length < 1 ? (
            <TableEmptyState
              title={"Tu empresa aún no tiene destinatarios cargados"}
              subtitle={
                "Aquí se mostrarán los destinatarios de tus facturas por pagar."
              }
            />
          ) : receivers.length >= 1 ? (
            <Table
              columns={receiversCols}
              data={receivers}
              editIcon
              editAction={openReceiverDrawer}
              setPageIndex={setPageIndex}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteCustomerModal}
              section="customers"
              supplier={customersScreen?.customerFilter?.value}
              setSupplier={customersScreen?.scustomerFilter?.setter}
              renderAutocomplete={renderReceiversAutocomplete}
              renderRowEndButtons={renderRowEndButtons}
              pageNumber={pageIndex}
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
};
