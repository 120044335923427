export const paymentsScreens = [
  {
    name: "Cuentas por pagar",
    to: "/payments/invoices",
  },
  {
    name: "Lotes",
    to: "/payments/pay-later",
  },
  {
    name: "Agenda",
    to: "/contacts/suppliers",
  },
];

export const payrollScreens = [
  {
    name: "Pago de nómina",
    to: "/payroll/salaries",
  },
  {
    name: "Lotes",
    to: "/payroll/pay-later",
  },
  {
    name: "Empleados",
    to: "/contacts/employees",
  },
];

export const collectionsMenuScreens = [
  {
    name: "Cuentas por cobrar",
    to: "/collections/index",
  },
  {
    name: "Agenda",
    to: "/contacts/customers",
  },
];

export const companiesBOSubmenu = [
  {
    name: "Suscripciones",
    to: "/Backoffice/subscriptions",
    view: "subscription",
  },
  {
    name: "Facturación",
    to: "/Backoffice/subscriptions/billing",
    view: "billing",
  },
];
