import { Typography } from "@mui/material";
import { SheetIcon, SiigoLogo, UpdateIcon } from "assets";
import { ButtonWithIcon, SyncBanner } from "commons/components";
import React, { useContext, useEffect, useState } from "react";
import { Spinner, Stack } from "react-bootstrap";
import { formatLocaleDate, handleSyncErpResponse } from "utils";
import { UserContext } from "contexts/UserContext";
import { CausationContext } from "contexts/CausationContext";
import { checkSiigoCredential, syncCausation } from "services";
import * as CausationService from "services/api/causation";
import { searchType } from "../../constants";
import { styles } from "./style";
import { urlBaseGoogleSheet } from "constants";
import { erpNames } from "constants";

export const SyncInformation = ({
  lastSyncCausationAt,
  formValues,
  handleOnChangeAutoComplete,
  setSupplierFound,
}) => {
  const { causationScreen, currentCompany } = useContext(UserContext);
  const { hasErpCausation } = useContext(CausationContext);
  const [syncingSiigo, setSyncingSiigo] = useState(false);
  const [lastSyncDate, setLastSyncDate] = useState(lastSyncCausationAt);

  const syncBanner = causationScreen?.syncBanner?.value;
  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const syncSiigo = () => {
    setSyncingSiigo(true);
    syncCausation().then((res) => {
      handleSyncErpResponse(res, onSuccess, setSyncBanner);
      setSyncingSiigo(false);
    });
  };
  const onSuccess = () => {
    setSyncingSiigo(false);
    checkSiigoCredential().then((res) => {
      setLastSyncDate(res?.syncValidation?.lastSyncCausationAt);
      updateSiigoSupplier();
    });
  };

  const updateSiigoSupplier = async () => {
    if (formValues.supplier.document_number) {
      const res = await CausationService.search(
        searchType.supplier,
        formValues.supplier.document_number
      );
      if (res.at(0)?.id) {
        await handleOnChangeAutoComplete("supplier", res.at(0));
      }
      const storedSupplier = await CausationService.searchErpSupplier({
        documentNumber: formValues.supplier.document_number,
      });
      setSupplierFound(storedSupplier);
    }
  };

  useEffect(() => {
    updateSiigoSupplier();
  }, []);

  const textParams = {
    [erpNames.siigoContador]: "Parámetros de Siigo contador",
    [erpNames.worldOffice]: "Parámetros de World Office",
  };

  const openSheetParams = () => {
    window.open(
      `${urlBaseGoogleSheet}${currentCompany?.erp_sheet_id}`,
      "_blank"
    );
  };

  return (
    <Stack spacing={1}>
      <div style={styles.container}>
        <div style={styles.flexRow}>
          <div style={styles.siigoLogo}>
            {hasErpCausation ? (
              <SheetIcon style={styles.siigoLogoMargin} />
            ) : (
              <SiigoLogo style={styles.siigoLogoMargin} />
            )}
          </div>
          {lastSyncDate && (
            <div style={styles.lastSyncDate}>
              <Typography sx={styles.lastUpdateText}>
                Última actualización
              </Typography>
              <Typography sx={styles.dateText}>
                {formatLocaleDate(lastSyncDate, "d MMM yyyy, HH:mm")}
              </Typography>
            </div>
          )}
          {hasErpCausation && (
            <div style={styles.lastSyncDate}>
              <Typography
                sx={styles.dateText}
                onClick={() => openSheetParams()}
              >
                {textParams[currentCompany?.erp_name] || "Parámetros"}
              </Typography>
            </div>
          )}
        </div>
        {syncingSiigo ? (
          <Spinner
            style={styles.spinner}
            animation="border"
            size="sm"
            variant="primary"
          />
        ) : (
          <ButtonWithIcon
            buttonLabel={"Actualizar"}
            icon={<UpdateIcon stroke="#5925DC" />}
            width="fit-content"
            style={styles.updateButton}
            action={() => {
              setSyncingSiigo(true);
              syncSiigo();
            }}
            innerTextStyle={{ color: "#5925DC" }}
          />
        )}
      </div>
      {syncBanner.visible && (
        <SyncBanner
          title={syncBanner.title}
          description={syncBanner.description}
          type={syncBanner.type}
          setSyncBanner={setSyncBanner}
          customStyle={styles.syncBanner}
        />
      )}
    </Stack>
  );
};
