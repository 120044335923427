import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "commons/buttons";

export const MarkModal = ({
  visible,
  handleClose,
  action,
  title,
  text,
  icon,
  style = {},
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body style={{ padding: "24px", ...style }}>
        <Box sx={styles.container}>
          {icon}
          <p style={styles.title}>{title}</p>
          <p style={styles.text}>{text}</p>
          <div style={styles.row}>
            <SecondaryButton text="Cancelar" action={handleClose} width="50%" />
            <PrimaryButton text="Confirmar" action={action} width="50%" />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
