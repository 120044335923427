import { CustomLink } from "../../components";

export const AccountItem = ({
  menuOptions = [],
  label,
  icon,
  handleClose,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {icon}
        <a style={{ color: "#6938EF", fontWeight: "500" }}>{label}</a>
      </div>
      <div style={{ paddingLeft: "24px" }}>
    {menuOptions?.map((menuOption) => (
      <CustomLink
        key={menuOption.title}
        onClick={handleClose}
        to={menuOption.action}
      >
        {menuOption.title}
      </CustomLink>
    ))}
      </div>
    </>
  );
};
