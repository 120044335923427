export const styles = {
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#101828",
  },
  accountingTitle: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#101828",
    backgroundColor: "#FFFFFF",
  },
  bankMovementsTitle: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#101828",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#667085",
    marginLeft: "2.5%",
  },
  statusOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085",
    marginTop: "24px",
    marginLeft: "2.5%",
    // paddingBottom: "12px",
    // borderBottom: "1px solid #667085",
  },
  statusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    marginLeft: "2.5%",
    // paddingBottom: "12px",
    // borderBottom: "2px solid #7f56d9",
  },
  expirationContainer: {
    marginTop: "12px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  expirationFirstItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    border: "1px solid #EAECF0",
  },
  expirationSecondItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationThirdItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationLastItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    border: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationItemTitle: {
    color: "#667085",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  expirationItemAmount: {
    color: "#101828",
    fontWeight: "600",
    fontSize: "20px",
    paddingLeft: "12px",
    lineHeight: "12px",
  },
  expirationExpiredTitle: {
    color: "#B42318",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  footer: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerPrimaryButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  footerSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    marginRight: "12px",
  },
  footerText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
  accountingTitleContainer: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginTop: 8,
    justifyContent: "space-between",
    position: "sticky",
    top: "-4px",
    backgroundColor: "#FFFFFF",
    marginLeft: "-16px",
    marginRight: "-16px",
    padding: "8px 16px 8px 16px",
    zIndex: 3,
    marginBottom: "-8px",
  },
  bankMovementsTitleContainer: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginTop: 8,
    marginLeft: 2,
    marginRight: 2,
    justifyContent: "space-between",
    width: "100%",
  },
  titleButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "36px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  dateButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    justifyContent: "space-evenly",
    textTransform: "capitalize",
    gap: "4px",
    width: "max-content",
  },
  closeButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    textTransform: "capitalize",
    gap: "4px",
    width: "190px",
  },

  seeMoreButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    justifyContent: "space-evenly",
    textTransform: "capitalize",
    gap: "4px",
    width: "158px",
  },
  typeButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    justifyContent: "space-evenly",
    textTransform: "capitalize",
    gap: "4px",
  },
  dateButtonTextContainer: {
    fontSize: "14px",
    display: "flex",
    alignItems: "baseline",
    fontWeight: "500",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tooltipTitle: {
    color: "#5522FF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "6px",
  },
  tooltipSubtitle: {
    color: "#5522FF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
  },
  introBannerContainer: {
    background: "#FCFCFD",
    padding: "16px 15px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "8px",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  introBannerTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#101828",
  },
  introBannerAction: {
    cursor: "pointer",
    marginTop: "2px",
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  introBannerLink: {
    color: "#5925DC",
    fontWeight: "500",
    fontSize: "14px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  typeFilterTitle: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#101828",
    marginBottom: "4px",
  },
  typeFilterSubtitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#475467",
  },
  divider: {
    height: "1px",
    backgroundColor: "#D0D5DD",
    marginTop: "12px",
    marginBottom: "24px",
    width: "100%",
  },
  radioInputLabel: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#344054",
    marginLeft: "2px",
  },
  checkboxInputLabel: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#101828",
    marginLeft: "12px",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    height: "-webkit-fill-available",
    marginTop: "16px",
    gap: "20px",
  },
  paginationPerPage: {
    height: "31px",
    fontSize: "14px",
    cursor: "pointer",
  },
  paginationFont: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  emptyStateBox: {
    height: "601px",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  emptyStateStack: {
    alignItems: "center",
    width: "100%",
    padding: "32px",
    backgroundColor: "#FFFFFF",
    marginTop: "8px",
    borderRadius: "12px",
  },
  emptyStateBtnInGroup: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    borderRadius: "8px",
    padding: "14px 16px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    color: "#344054",
    WebkitTransition: "none",
    transition: "none",
    "&:hover": {
      border: "1px solid #EAECF0",
    },
    "&:disabled": {
      borderColor: "#5925dc80",
    },
    "& .MuiButton-startIcon": {
      width: "46px",
      height: "36px",
      borderRadius: "4px",
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
      margin: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-start",
    },
  },
  emptyStateTitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#667085",
  },
  buttonStepEmptyStateText: {
    color: "#667085",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  buttonStepEmptyStateTitle: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
  },
  emptyInfoText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "flex-start",
    color: "#667085",
  },
  emptyRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    justifyContent: "flex-start",
    width: "100%",
  },
  textArea: {
    padding: "10px 14px 10px 14px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    opacity: "0px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    background: "#FFFFFF",
    fontSize: "14px",
    height: "auto",
    color: "#667085",
    width: "100%",
  },
  backContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
  },
  backText: {
    color: "#5925DC",
    fontSize: "14px",
    fontWeight: "500",
  },
  bankMovementsContainer: {
    backgroundColor: "#FFFFFF",
    minWidth: "40%",
    width: "40%",
    maxWidth: "40%",
    borderRadius: "8px",
    padding: "4px 16px 4px 16px",
    gap: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    border: "1px solid #D0D5DD",
    maxHeight: "-webkit-fill-available",
  },
  movementAliasContainer: {
    width: "100%",
    backgroundColor: "#F9FAFB",
    padding: "10px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  filtersRow: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  },
  movementsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    width: "100%",
    maxHeight: "-webkit-fill-available",
    overflowY: "scroll",
    scrollbarGutter: "stable",
    scrollbarWidth: "thin !important",
    scrollbarColor: "#667085 #ededed !important",
    marginBottom: "8px",
  },
  movementsEmptyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "500px",
  },
  movementEmptyText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1D2939",
    maxWidth: "300px",
    textAlign: "center",
    alignSelf: "center",
  },
  columnsContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    gap: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "75vh",
  },
  accountingContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    borderRadius: "8px",
    padding: "4px 16px 4px 16px",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    border: "1px solid #D0D5DD",
    maxHeight: "-webkit-fill-available",
    overflowY: "scroll",
    scrollbarGutter: "stable",
    scrollbarWidth: "thin !important",
    scrollbarColor: "#667085 #ededed !important",
  },
  accountingColumn: {
    width: "100%",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  reconciledContainer: {
    width: "100%",
    height: "max-content",
    backgroundColor: "#F9FAFB",
    padding: "16px",
    borderRadius: "8px",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  reconciledHeader: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#1D2939",
    marginBottom: "8px",
  },
  reconciledText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#1D2939",
  },
  reconciledTableContainer: {
    width: "100%",
    height: "max-content",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  reconciledTableRow: {
    width: "100%",
    height: "max-content",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  reconciledTableHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#101828",
  },
  reconciledDivider: {
    backgroundColor: "#EAECF0",
    height: "1px",
    width: "100%",
    marginBottom: "8px",
  },
  reconciledTableText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#475467",
  },
  reconciledTextarea: {
    padding: "10px 14px 10px 14px",
    gap: "8px",
    borderRadius: "8px",
    border: "none",
    background: "#FFFFFF",
    fontSize: "14px",
    height: "auto",
    color: "#667085",
    width: "100%",
  },
  accountingRegisterEmptyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "500px",
  },
  accountingRegisterEmptyText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1D2939",
    maxWidth: "200px",
    textAlign: "center",
    alignSelf: "center",
  },
  accountingItemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "2px",
  },
  supplierTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
    marginTop: "8px",
  },
  emptySupplierContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "250px",
    marginTop: "48px",
  },
  emptySupplierTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1D2939",
    maxWidth: "260px",
    textAlign: "center",
    alignSelf: "center",
    marginTop: "32px",
    marginBottom: "8px",
  },
  emptySupplierText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#667085",
    maxWidth: "320px",
    textAlign: "center",
    alignSelf: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "16px",
  },
  fullWidth: {
    width: "100%",
  },
  formTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
    marginBottom: "6px",
  },
  advanceContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
  },
  advanceTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#101828",
  },
  advanceAmount: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#101828",
  },
  formButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    width: "100%",
    justifyContent: "flex-end",
  },
  reconcileEmptyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "500px",
  },
  reconcileEmptyTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1D2939",
    maxWidth: "200px",
    textAlign: "center",
    alignSelf: "center",
    marginTop: "32px",
    marginBottom: "8px",
  },
  reconcileEmptyText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#667085",
    maxWidth: "320px",
    textAlign: "center",
    alignSelf: "center",
  },
  selectedMovement: {
    width: "100%",
    height: "max-content",
    border: "2px solid #9B8AFB",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: "#FAFAFF",
    borderRadius: "8px",
  },
  movement: {
    width: "100%",
    height: "max-content",
    border: "1px solid #EAECF0",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  movementRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  movementColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "2px",
  },
  movementText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  movementCenteredRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  },
  movementAmount: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#101828",
  },
  movementStatusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  movementDocumentsTitle: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#1D2939",
    marginTop: "16px",
    marginBottom: "8px",
    alignSelf: "flex-start",
  },
  movementDocumentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "8px",
  },
  movementDocumentText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#667085",
  },
  inputLabel: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "440px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "max-content",
    alignItems: "center",
  },
  dropdownItemIconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "initial",
    alignItems: "center",
  },
  dropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
    alignSelf: "center",
    display: "contents",
  },
};
