import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SearchIcon } from "assets";
import { useEffect, useState } from "react";
import { CompanySearchOption } from "./CompanySearchOption";
import { useMediaQuery } from "react-responsive";

const isAlphanumericKey = (event) => {
  const key = event.key;
  const alphanumericRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ0-9]$/;
  return alphanumericRegex.test(key);
};

export const ServiceCompanyDropdown = ({
  onInputChange,
  loading,
  value,
  onChange,
  options,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [typing, isTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (loading || !typing) return;

    const timer = setTimeout(() => {
      isTyping(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [typing, loading]);

  const loadingText = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>Buscando...</span> <CircularProgress size={15} />
    </div>
  );

  return (
    <div
      style={{
        width: isMobile ? "100%" : "350px",
        minWidth: isMobile ? "unset" : "350px",
      }}
    >
      <label className="debts-search-form-label" htmlFor="searchOptionSelected">
        Ingresa el nombre del servicio
      </label>
      <Autocomplete
        id="searchOptionSelected"
        onKeyDown={(e) => isAlphanumericKey(e) && isTyping(true)}
        options={options}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={
          typing
            ? "Escribiendo..."
            : inputValue
              ? `La empresa ${inputValue} no está disponible para pagar. Revisa si el nombre que ingresaste es correcto.`
              : "Cargando..."
        }
        size="small"
        renderOption={(props, option) => (
          <li {...props}>
            <CompanySearchOption company={option} />
          </li>
        )}
        getOptionLabel={(option) => option?.name || ""}
        onChange={(event, option) => onChange(option)}
        onInputChange={(e, newValue) => {
          setInputValue(newValue);
          if (!e || newValue === 0) {
            return;
          }
          if (!newValue) {
            return onInputChange("");
          }
          onInputChange(typeof newValue !== "string" ? "" : newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Buscar"
          />
        )}
        value={value}
      />
    </div>
  );
};
