import { useContext } from "react";
import { Footer } from "../../../commons";
import { PaymentsContext, UserContext } from "../../../contexts";
import { invoiceCanBeDeleted } from "./utils";
import { PAID_OUTSIDE } from "constants";

export const InvoiceFooter = ({
  selectedRows,
  selectedRowsIds,
  totalAmount,
  deleteSelectedInvoices,
  openMarkAsPaidModal,
  openMarkAsPendingModal,
  payLater,
  openWompiModal,
  totalSelected,
  pageIndex,
  disabled,
  footerDropdown,
  openCausationMarkAsPendingModal,
}) => {
  const { approvationNeeded, currentUserIsAdmin, currentCompany } =
    useContext(UserContext);

  const { approveInvoices, subscription } = useContext(PaymentsContext);
  const hasOneUnapproved = () =>
    selectedRows.some((invoice) => !invoice.original.approved);

  const hasTransactionId = () =>
    selectedRows.some((invoice) => !invoiceCanBeDeleted(invoice?.original));

  const hasInvoicesPaidOutside = () =>
    selectedRows.some((invoice) => invoice.original.status === PAID_OUTSIDE);

  const hasOneNegativeValue = selectedRows.some(
    (row) => row.original.amount_total < 0
  );

  return (
    <Footer
      disabled={disabled || selectedRows.length === 0}
      disabledApproval={
        approvationNeeded && hasOneUnapproved() && !currentUserIsAdmin
      }
      totalAmount={totalAmount}
      showDelete
      deleteAction={() => {
        deleteSelectedInvoices(selectedRowsIds, pageIndex);
      }}
      showMarkAsPaid
      disabledMarkAsPaid={selectedRows.length < 1}
      markAsPaidAction={openMarkAsPaidModal}
      markAsPendingAction={openMarkAsPendingModal}
      showPayLater
      disablePayLater={selectedRows.length < 1 || totalAmount <= 0}
      payLaterAction={payLater}
      openWompiModal={openWompiModal}
      disableWompi={selectedRows.length < 1 || totalAmount <= 0}
      showApprove={approvationNeeded && currentUserIsAdmin}
      approveAction={() => {
        approveInvoices(selectedRowsIds);
      }}
      openCausationMarkAsPendingModal={openCausationMarkAsPendingModal}
      totalSelected={totalSelected}
      disableDelete={hasTransactionId()}
      disabledTooltip={disabled}
      footerDropdown={footerDropdown}
      subscription={subscription}
      hasInvoicesPaidOutside={hasInvoicesPaidOutside()}
      hasOneNegativeValue={hasOneNegativeValue}
      showCausationButtons={currentCompany?.isCausationEnabled}
      hasOneToMarkAsCausation={selectedRows.some(
        (row) => row.original?.causation_state === "draft"
      )}
      hasOneMarkedAsCausation={selectedRows.some(
        (row) =>
          row.original?.causation_state === "done" && row.original?.created_at
      )}
    />
  );
};
