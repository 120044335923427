import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { SearchIcon } from "assets";

export const renderAutocomplete = ({
  placeholder = "Buscar",
  searchOptions = [],
  filterOptions = (item) => item,
  getSearchOptions = () => {},
  onTableSearchSelected,
  inputValue = "",
  value = {
    name: "",
  },
  isMobile,
} = {}) => {
  return (
    <>
      <Autocomplete
        inputValue={inputValue}
        options={searchOptions}
        size="small"
        sx={{
          width: isMobile ? "100%" : "350px",
          minWidth: isMobile ? "164px" : "350px",
        }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            onTableSearchSelected(option);
          } else {
            onTableSearchSelected();
          }
        }}
        onInputChange={(e) => {
          if (!e || e?.target?.value === 0) {
            return;
          }
          if (!e.target.value) {
            return getSearchOptions("");
          }
          getSearchOptions(
            typeof e.target.value !== "string" ? "" : e.target.value
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14, backgroundColor: "#FFFFFF" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
          />
        )}
        value={value}
      />
    </>
  );
};
