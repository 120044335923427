import { CloseButton } from "react-bootstrap";

const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export const ModalHeader = ({ onClose }) => {
  return (
    <div style={styles.headerRow}>
      <div onClick={() => onClose()}>
        <CloseButton style={{ boxShadow: "none" }} />
      </div>
      <div
        className="modalTitle"
        style={{ marginLeft: "16px", fontSize: "18px" }}
      >
        Carga de servicios
      </div>
    </div>
  );
};
