import React, { useRef, useState } from "react";
import { Divider, Menu, Typography, Stack, Button } from "@mui/material";
import "../stylesFilter.css";
import { styles } from "../styles";
import { SecondaryButton } from "commons/buttons";
import { FilterIcon } from "assets";

const VALUE_TYPE = {
  all: "",
  caused: "done",
  notCaused: "draft",
};

export const CausationFilterDropdown = ({
  selectFilterCausation,
  setSelectFilterCausation,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (option) => {
    setSelectFilterCausation(option);
    handleClose();
  };

  const options = [
    { title: "Todas", label: "", value: VALUE_TYPE.all },
    { title: "Causadas", label: "Causadas", value: VALUE_TYPE.caused },
    { title: "No causadas", label: "No causadas", value: VALUE_TYPE.notCaused },
  ];

  return (
    <div style={styles.causationFilterContainer}>
      <SecondaryButton
        text={selectFilterCausation?.label || "Causación"}
        action={handleClick}
        width="fit-content"
        icon={<FilterIcon />}
        innerStyle={{
          justifyContent: "space-between",
        }}
        textStyle={{ paddingLeft: 6, alignSelf: "center", color: "#667085" }}
      />
      <Menu
        id="causation-menu-filter"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        slotProps={{
          paper: {
            style: {
              minHeight: 90,
              width: 390,
              padding: 16,
            },
          },
          root: { sx: { ".MuiList-root": { padding: 0 } } },
        }}
      >
        <Typography sx={styles.typeFilterTitle}>
          Filtra por estado de causación
        </Typography>
        <Divider style={styles.divider} />
        <Stack spacing={1} sx={{ mt: 2 }}>
          {options.map((option) => (
            <Button
              key={option.value}
              onClick={() => onSelect(option)}
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                color:
                  selectFilterCausation?.value === option.value
                    ? "#6938EF"
                    : "#344054",
                "&:hover": {
                  color: "#6938EF",
                },
                textTransform: "none",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {option.title}
            </Button>
          ))}
        </Stack>
      </Menu>
    </div>
  );
};
