import { Skeleton } from "@mui/material";

export const CustomSkeleton = ({ rows = 5 }) => {
  return (
    <div
      style={{
        marginLeft: "2.5%",
        marginRight: "2.5%",
        marginTop: "24px",
        backgroundColor: "#F6F7F9",
      }}
    >
      {[...Array(rows)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={"100%"}
          height={40}
          style={{ marginTop: "12px" }}
        />
      ))}
    </div>
  );
};
