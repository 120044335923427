import * as amplitude from "@amplitude/analytics-browser";
import {
  ContactsIcon,
  PayanaIcon,
  ServicesIcon,
  ToChargeIcon,
  ToPayIcon,
  TransactionsIcon,
  UsersIcon,
} from "../../../assets";
import { Link, useLocation } from "react-router-dom";
import { CustomLink } from "../components";
import { UserContext } from "../../../contexts";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { Tooltip } from "commons/components";

export const Sidebar = ({ showMenuOptions = true }) => {
  const location = useLocation();

  const { currentUser } = useContext(UserContext);

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  if (isMobile) {
    return null;
  }

  const menuItems = [
    {
      to: "/payments",
      text: "Pago a proveedores",
      icon: <ToPayIcon />,
      activeIcon: <ToPayIcon color="#ffffff" />,
    },
    {
      to: "/payroll",
      text: "Pago de nómina",
      icon: <UsersIcon />,
      activeIcon: <UsersIcon color="#ffffff" />,
    },
    {
      to: "/services",
      text: "Servicios",
      icon: <ServicesIcon />,
      activeIcon: <ServicesIcon color="#ffffff" />,
    },
    {
      to: "/collections",
      text: "Cuentas por cobrar",
      icon: <ToChargeIcon />,
      activeIcon: <ToChargeIcon color="#ffffff" />,
    },
  ];

  const newMenuItems = [
    {
      to: "/transactions",
      text: "Transacciones",
      icon: <TransactionsIcon />,
      activeIcon: <TransactionsIcon color="#ffffff" />,
      onClick: () =>
        amplitude.logEvent("VIEW_TRANSACTIONS_TAB", {
          user_id: currentUser.id,
        }),
      className: "transactionsSection",
    },
    {
      to: "/contacts",
      text: "Agenda",
      icon: <ContactsIcon />,
      activeIcon: <ContactsIcon color="#ffffff" />,
      className: "contactsSection",
    },
  ];

  return (
    <Box className="nav" sx={styles.sidebar}>
      <div style={styles.logoContainer}>
        <Link
          to={
            currentUser.backoffice_user
              ? "/backoffice-receipts"
              : "/payments/invoices"
          }
          style={{ padding: 0, margin: 0 }}
        >
          <PayanaIcon width="32px" height="32px" />
        </Link>
      </div>

      {showMenuOptions && (
        <Box sx={styles.menuContainer}>
          <Box sx={styles.menuList} className="sidebarSections">
            {menuItems.map((item, index) =>
              item.show === undefined || item.show ? (
                <Tooltip title={item.text} placement="right">
                  <span>
                    <CustomLink
                      key={index}
                      to={item.to}
                      onClick={item.onClick}
                      style={{
                        width: "44px",
                        height: "44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      {location.pathname.startsWith(item.to)
                        ? item.activeIcon
                        : item.icon}
                    </CustomLink>
                  </span>
                </Tooltip>
              ) : null
            )}
          </Box>
          <Box sx={{ ...styles.menuList, marginTop: "12px" }}>
            {newMenuItems.map((item, index) =>
              item.show === undefined || item.show ? (
                <Tooltip title={item.text} placement="right">
                  <span>
                    <CustomLink
                      key={index}
                      to={item.to}
                      onClick={item.onClick}
                      style={{
                        width: "44px",
                        height: "44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                      className={item?.className}
                    >
                      {location.pathname.startsWith(item.to)
                        ? item.activeIcon
                        : item.icon}
                    </CustomLink>
                  </span>
                </Tooltip>
              ) : null
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
