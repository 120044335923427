import {
  OUTSTANDING_BALANCE,
  PAID_OUTSIDE,
  PENDING,
  SENT,
} from "../../../constants/status";
import { TrackJS } from "trackjs";

const invoiceCanBeDeleted = (invoice) =>
  !["pay_later", "approved", "in_process"].includes(
    invoice?.transaction_status
  );

const saveSelectedRowsToStorage = (rows, SELECTED_INVOICES_KEY) => {
  try {
    const simplifiedRows = rows.map((row) => ({
      id: row.original.id,
      origin: row.original.origin,
    }));
    localStorage.setItem(SELECTED_INVOICES_KEY, JSON.stringify(simplifiedRows));
  } catch (error) {
    console.warn("Failed to save to localStorage:", error);
    TrackJS.console.error(error.message);
  }
};

const getSelectedRowsFromStorage = (invoices, SELECTED_INVOICES_KEY) => {
  try {
    const saved = localStorage.getItem(SELECTED_INVOICES_KEY);
    if (!saved) return [];

    const savedIds = JSON.parse(saved);
    const invoicesToReturn = savedIds
      .map((savedRow) => {
        const invoice = invoices.find(
          (inv) =>
            inv.id === savedRow.id &&
            (inv.status === PENDING ||
              inv.status === SENT ||
              inv.status === OUTSTANDING_BALANCE ||
              inv.status === PAID_OUTSIDE)
        );
        return invoice ? { original: invoice } : null;
      })
      .filter(Boolean);
    saveSelectedRowsToStorage(invoicesToReturn, SELECTED_INVOICES_KEY);
    return invoicesToReturn;
  } catch (error) {
    console.warn("Failed to load from localStorage:", error);
    TrackJS.console.error(error.message);
    return [];
  }
};

const cleanStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.warn("Failed to clean storage:", error);
  }
};

export {
  invoiceCanBeDeleted,
  saveSelectedRowsToStorage,
  getSelectedRowsFromStorage,
  cleanStorage,
};
