import React from "react";

export const ToPayIcon = ({ width = 22, height = 22, color = "#101828" }) => (
  <svg width={width} height={height} viewBox="0 0 25 25" fill="none">
    <path
      d="M10.5596 6.54388V18.4555"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3168 8.71136H9.18122C8.11216 8.71136 7.2541 9.56023 7.2541 10.6023C7.2541 11.6444 8.1192 12.4933 9.18122 12.4933H11.9383C13.0073 12.4933 13.8654 13.3421 13.8654 14.3842C13.8654 15.4332 13.0003 16.2752 11.9383 16.2752H7.24707"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6203 19.2702C18.6203 21.0507 17.1433 22.5 15.3287 22.5H5.79158C3.97699 22.5 2.5 21.0507 2.5 19.2702V5.72981C2.5 3.94928 3.97699 2.5 5.79158 2.5H15.3287C17.1433 2.5 18.6203 3.94928 18.6203 5.72981"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2039 12.9359L22.5 12.9359"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8485 10.3333L22.5 12.9351L19.8485 15.53"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
