import React from "react";
import { Steps } from "intro.js-react";
import { styles } from "../styles";

export const NewNavigationIntro = ({ enabled, onComplete, onExit }) => {
  const tutorialSteps = [
    {
      element: ".sidebarSections",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Módulos</p>
          <p style={styles.tooltipSubtitle}>
            Acceso directo a los módulos de Pago a proveedores, Nómina,
            Servicios y Cobros.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".transactionsSection",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Transacciones</p>
          <p style={styles.tooltipSubtitle}>
            Igual que siempre! Todas tus transacciones en un mismo lugar.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".contactsSection",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Nueva agenda unificada</p>
          <p style={styles.tooltipSubtitle}>
            Aquí podrás ver todos tus contactos: proveedores, empleados,
            clientes, destinatarios y servicios.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".paymentsSubNavigation",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Pestañas de visualización</p>
          <p style={styles.tooltipSubtitle}>
            Dentro de cada módulo, selecciona si quieres{" "}
            <strong>visualizar por documento o por lote preparado</strong>.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".companiesSwitch",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Maneja tus múltiples empresas</p>
          <p style={styles.tooltipSubtitle}>
            Selecciona desde aquí la empresa en la que estás operando.
          </p>
        </div>
      ),
      position: "bottom",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".accountSection",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Configuración general</p>
          <p style={styles.tooltipSubtitle}>
            Visualiza y edita tu información de usuario y de la empresa actual
            en la que estas operando.
          </p>
        </div>
      ),
      position: "bottom",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
  ];

  return (
    <Steps
      enabled={enabled}
      steps={tutorialSteps}
      initialStep={0}
      onComplete={onComplete}
      onExit={onExit}
      options={{
        showBullets: true,
        prevLabel: "Anterior",
        nextLabel: "Siguiente",
        doneLabel: "Entendido",
        hidePrev: true,
      }}
    />
  );
};
