export const INITIAL_STATE = {
  view: "form",
  searchOptionSelected: null,
  servicesSearchOptions: [],
  identifierValue: "",
  serviceSearchInputText: "",
};

const actionMap = {
  RESET_STATE: () => INITIAL_STATE,
  SHOW_DEBTS_LIST: (state, action) => ({
    ...state,
    view: "show_debts",
    debts: action.payload.debts,
  }),
  HANDLE_SERVICE_SEARCH_OPTION_CHANGED: (state, action) => ({
    ...state,
    searchOptionSelected: action.payload.selectedOption,
    modality: action.payload.selectedOption?.modalities?.at(0),
  }),
  HANDLE_SERVICE_SEARCH_OPTIONS: (state, action) => ({
    ...state,
    servicesSearchOptions: action.payload.services,
  }),
  HANDLE_POPULAR_SERVICE_SEARCH_OPTIONS: (state, action) => ({
    ...state,
    popularServicesSearchOptions: action.payload.services,
    servicesSearchOptions: action.payload.services,
  }),
  HANDLE_SERVICE_IDENTIFIER_VALUE_CHANGED: (state, action) => ({
    ...state,
    identifierValue: action.payload.identifierValue,
  }),
  HANDLE_SERVICE_MODALITY_CHANGED: (state, action) => ({
    ...state,
    modality: action.payload.modality,
  }),
  HANDLE_SERVICE_SEARCH_INPUT_TEXT_CHANGED: (state, action) => ({
    ...state,
    serviceSearchInputText: action.payload.serviceSearchInputText,
  }),
  HANDLE_BACK_TO_SERVICE_COMPANY_SEARCH: (state) => ({
    ...state,
    view: "form",
  }),
  HANDLE_SERVICE_REGISTERED: (state, action) => ({
    ...state,
    serviceRegistered: action.payload.service,
  }),
};

export const createServiceReducer = (state, action) => {
  return actionMap[action.type](state, action);
};
