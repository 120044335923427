export const UsersIcon = ({ width = 22, height = 22, color = "#101828" }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
    <path
      d="M11 10.5V9.5C11 8.56808 10.3626 7.78503 9.5 7.56301M7.75 1.64538C8.48296 1.94207 9 2.66066 9 3.5C9 4.33934 8.48296 5.05793 7.75 5.35462M8.5 10.5C8.5 9.56812 8.5 9.10218 8.34776 8.73463C8.14477 8.24458 7.75542 7.85523 7.26537 7.65224C6.89782 7.5 6.43188 7.5 5.5 7.5H4C3.06812 7.5 2.60218 7.5 2.23463 7.65224C1.74458 7.85523 1.35523 8.24458 1.15224 8.73463C1 9.10218 1 9.56812 1 10.5M6.75 3.5C6.75 4.60457 5.85457 5.5 4.75 5.5C3.64543 5.5 2.75 4.60457 2.75 3.5C2.75 2.39543 3.64543 1.5 4.75 1.5C5.85457 1.5 6.75 2.39543 6.75 3.5Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
