import React from "react";
import { styles } from "../styles";
import {
  ConnectorIcon,
  InfoIcon,
  MoneyDoneIcon,
  MoneyDotIcon,
  MoneyFinishedIcon,
  MoneyRejectedIcon,
} from "assets";
import { MoneyCurrentStepIcon } from "assets/svg/MoneyCurrentStepIcon";
import { formatLocaleDate } from "utils";
import { ACHInfoDisclaimer } from "./ACHInfoDisclaimer";

export const MoneyJourneySection = ({ transaction }) => {
  const icon = {
    payIn: <MoneyDoneIcon />,
    payOut: <MoneyDoneIcon />,
    payOutInProcess: <MoneyCurrentStepIcon />,
    rejected: <MoneyRejectedIcon />,
    retry: <MoneyDotIcon />,
    dispersed: <MoneyDoneIcon />,
    refunded: <MoneyDoneIcon />,
  };

  const beneficiaryType = {
    invoice: "proveedor",
    service: "proveedor",
    payroll: "empleado",
    collection: "cobrador adicional",
  };

  const payInTitle = {
    invoice: "Recibimos el pago",
    payroll: "Recibimos el pago",
    service: "Recibimos el pago",
    collection: "Recibimos el dinero",
  };

  const payOutTitle = {
    invoice: "Estamos dispersando el pago",
    service: "Estamos dispersando el pago",
    payroll: "Estamos dispersando el pago",
    collection: "Estamos dispersando el dinero",
  };

  const rejectedTitle = {
    invoice: "El pago se rechazó",
    payroll: "El pago se rechazó",
    service: "El pago se rechazó",
    collection: "El cobro se rechazó",
  };

  const payInText = {
    invoice: "Se lo enviaremos a tu proveedor",
    payroll: "Se lo enviaremos a tu empleado",
    collection: "Tu cliente nos envió el dinero",
    service: "Se lo enviaremos al proveedor del servicio",
  };

  const payOutText = {
    invoice: "Estamos enviando el pago a tu proveedor",
    payroll: "Estamos enviando el pago a tu empleado",
    collection: "Le estamos enviando el dinero al cobrador adicional",
    service: "Estamos enviando el pago al proveedor del servicio",
  };

  const rejectedText = {
    invoice: "El proveedor no pudo recibir el dinero",
    payroll: "El empleado no pudo recibir el dinero",
    collection: "No pudimos enviar el dinero",
    service: "El proveedor no pudo recibir el dinero",
  };

  const title = {
    payIn: payInTitle[transaction.operation_type],
    payOut: payOutTitle[transaction.operation_type],
    rejected: rejectedTitle[transaction.operation_type],
    retry: "Reintento de pago",
    dispersed: "Dispersado",
    refunded: "Te devolvimos tu dinero",
  };

  const text = {
    payIn: payInText[transaction.operation_type],
    payOut: payOutText[transaction.operation_type],
    rejected: rejectedText[transaction.operation_type],
    retry: `Enviaremos el pago nuevamente`,
    dispersed: `El ${
      beneficiaryType[transaction.operation_type]
    } recibió tu dinero`,
    refunded: "Recibiste el dinero en tu cuenta",
  };

  const renderStep = (step, index) => {
    const isLastItem = index === transaction.receipt_histories.length - 1;
    const isStepPayout = step.type === "payOut";
    const isStepPayin = step.type === "payIn";

    return (
      <div key={step.date} style={styles.moneyJourneySectionRow}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLastItem && isStepPayout
            ? icon["payOutInProcess"]
            : icon[step.type]}
          {isLastItem && isStepPayin && (
            <div style={{ marginTop: "6px" }}>
              <ConnectorIcon
                color={isStepPayin && isLastItem ? "#EAECF0" : "#6938EF"}
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ ...styles.moneyJourneySectionRow, gap: "6px" }}>
            <p style={styles.moneyJourneySectionTitle}>{title[step.type]}</p>
            <p style={styles.moneyJourneySectionDate}>
              {formatLocaleDate(step.date, "dd/MM/yyyy h:mm a")}
            </p>
          </div>
          <p style={styles.moneyJourneySectionText}>{text[step.type]}</p>
          {isLastItem && isStepPayout && transaction.is_ach && (
            <ACHInfoDisclaimer />
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={styles.sectionContainer} className="moneyJourneySection">
      <p style={styles.sectionTitle}>Recorrido del dinero</p>
      {transaction.receipt_histories?.map((step, index) => {
        return renderStep(step, index);
      })}
      {transaction.receipt_histories?.at(
        transaction.receipt_histories.length - 1
      ).type === "payIn" && (
        <>
          <div style={styles.moneyJourneySectionRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MoneyFinishedIcon />
              <div style={{ marginTop: "6px" }}>
                <ConnectorIcon color="#EAECF0" />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ ...styles.moneyJourneySectionRow, gap: "6px" }}>
                <p
                  style={{
                    ...styles.moneyJourneySectionTitle,
                    color: "#667085",
                  }}
                >
                  {title["payOut"]}
                </p>
              </div>
              <p
                style={{ ...styles.moneyJourneySectionText, color: "#667085" }}
              >
                {text["payOut"]}
              </p>
              {transaction.is_ach && <ACHInfoDisclaimer />}
            </div>
          </div>
          <div style={styles.moneyJourneySectionRow}>
            <MoneyFinishedIcon />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ ...styles.moneyJourneySectionRow, gap: "6px" }}>
                <p
                  style={{
                    ...styles.moneyJourneySectionTitle,
                    color: "#667085",
                  }}
                >
                  {title["dispersed"]}
                </p>
              </div>
              <p
                style={{ ...styles.moneyJourneySectionText, color: "#667085" }}
              >
                {text["dispersed"]}
              </p>
            </div>
          </div>
        </>
      )}
      {transaction.receipt_histories?.at(
        transaction.receipt_histories.length - 1
      ).type === "payOut" && (
        <div style={styles.moneyJourneySectionRow}>
          <MoneyFinishedIcon />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ ...styles.moneyJourneySectionRow, gap: "6px" }}>
              <p
                style={{ ...styles.moneyJourneySectionTitle, color: "#667085" }}
              >
                {title["dispersed"]}
              </p>
            </div>
            <p style={{ ...styles.moneyJourneySectionText, color: "#667085" }}>
              {text["dispersed"]}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
