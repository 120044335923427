import React from "react";
import { styles } from "../styles";
import { MovementsNotReconciledEmpty, MovementsReconciledEmpty } from "assets";
import { Typography } from "@mui/material";

export const MovementsEmptyState = ({ reconciliationOption }) => {
  return (
    <div style={styles.movementsEmptyContainer}>
      {reconciliationOption === "not_reconciled" ? (
        <MovementsNotReconciledEmpty />
      ) : (
        <MovementsReconciledEmpty />
      )}
      <Typography sx={styles.movementEmptyText}>
        {reconciliationOption === "not_reconciled"
          ? "No encontramos movimientos de dinero para conciliar"
          : "Aún no tienes movimientos de dinero conciliados"}
      </Typography>
    </div>
  );
};
