import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { styles } from '../../InvoicesScreen/styles.js';
import { servicesTableStatusFilters } from 'constants/services';
import { UserContext } from '../../../contexts';
import { useMediaQuery } from 'react-responsive';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export const StatusFilters = ({
    statusSelected,
    setStatusSelected,
    isBO = false,
    renderDownloadButton = () => {},
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 481px)' });
    const { approvationNeeded, currentUser } = useContext(UserContext);
    const statusFilters = (
        <div style={styles.container}>
            <div style={styles.rowSpaceBetween}>
                <div style={styles.row}>
                    {servicesTableStatusFilters(
                        approvationNeeded,
                        currentUser.role?.name
                    ).map(statusFilter => {
                        return (
                            <Button
                                variant="text"
                                key={statusFilter.label}
                                sx={
                                    statusSelected === statusFilter.value
                                        ? {
                                              ...styles.firstStatusOptionsSelected,
                                              minWidth: isMobile ? '110px' : '',
                                              textWrap: 'nowrap',
                                          }
                                        : {
                                              ...styles.firstStatusOptions,
                                              minWidth: isMobile ? '110px' : '',
                                              textWrap: 'nowrap',
                                          }
                                }
                                onClick={() => {
                                    setStatusSelected(statusFilter.value);
                                }}>
                                {statusFilter.label}
                            </Button>
                        );
                    })}
                    {isBO && (
                        <Button
                            variant="text"
                            sx={
                                statusSelected === 'processing'
                                    ? styles.firstStatusOptionsSelected
                                    : styles.firstStatusOptions
                            }
                            onClick={() => {
                                setStatusSelected('processing');
                            }}>
                            Procesando
                        </Button>
                    )}
                </div>
                {!isMobile && renderDownloadButton()}
            </div>
            <hr style={styles.separator} />
        </div>
    );
    if (isMobile) {
        return <ScrollMenu onWheel={onWheel}>{statusFilters}</ScrollMenu>;
    }
    return statusFilters;
};
