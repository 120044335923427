import React from "react";
import { Button, Typography } from "@mui/material";

export const ButtonWithIcon = ({
  action,
  buttonLabel,
  icon = null,
  width = "100%",
  style = {},
  endIcon = null,
  disabled = false,
  innerTextStyle = {},
}) => {
  const styles = {
    backgroundColor: disabled ? "#a181fb" : "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    border: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#4f29b6",
    },
    textWrap: "nowrap",
    ...style,
  };

  return (
    <Button
      onClick={action}
      startIcon={icon}
      endIcon={endIcon}
      sx={styles}
      disabled={disabled}
    >
      <Typography
        sx={{
          textTransform: "none",
          fontSize: "14px",
          fontWeight: 500,
          color: "#FFFFFF",
          ...innerTextStyle,
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};
