import React from "react";

export const ServicesIcon = ({
  width = 22,
  height = 22,
  color = "#101828",
}) => (
  <svg width={width} height={height} viewBox="0 0 26 24" fill="none">
    <line
      x1="16.125"
      y1="12.75"
      x2="4.66667"
      y2="12.75"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M11.9583 9L16.125 13L11.9583 17"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.0837 2H6.66699C5.56242 2 4.66699 2.89543 4.66699 4V20C4.66699 21.1046 5.56242 22 6.66699 22H19.3337C20.4382 22 21.3337 21.1046 21.3337 20V8L15.0837 2Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
