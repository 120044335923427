import React from "react";

export const ContactsIcon = ({
  width = 22,
  height = 22,
  color = "#101828",
}) => (
  <svg width={width} height={height} viewBox="0 0 26 24" fill="none">
    <path
      d="M17.0332 17.3802V16.1741C17.0332 15.5343 16.7685 14.9208 16.2973 14.4684C15.8261 14.016 15.1869 13.7619 14.5205 13.7619H9.49513C8.82872 13.7619 8.1896 14.016 7.71838 14.4684C7.24715 14.9208 6.98242 15.5343 6.98242 16.1741V17.3802"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0078 11.3482C13.3956 11.3482 14.5205 10.2682 14.5205 8.936C14.5205 7.60378 13.3956 6.5238 12.0078 6.5238C10.6201 6.5238 9.49512 7.60378 9.49512 8.936C9.49512 10.2682 10.6201 11.3482 12.0078 11.3482Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.33301"
      y="2.75"
      width="17.3492"
      height="18.1032"
      rx="2.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <line
      x1="23.125"
      y1="5.46429"
      x2="23.125"
      y2="7.58334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="23.125"
      y1="10.8929"
      x2="23.125"
      y2="13.0119"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="23.125"
      y1="16.3214"
      x2="23.125"
      y2="18.4405"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
