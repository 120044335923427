import { Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import {
  PrimaryButton,
  SecondaryButton,
  RedBorderButton,
  ThirdButton,
  DropdownButton,
} from "../../buttons";
import { styles } from "./styles";
import { useIsUserAllowedTo } from "../../../utils";
import { causationMarkAs, permissions } from "../../../constants";
import { Tooltip } from "commons/components";
import { Dropdown } from "react-bootstrap";
import {
  BankNoteIcon,
  CheckDoneIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CrownIcon,
  DeleteIcon,
  ReturnPendingIcon,
  RollbackIcon,
  SuccessIcon,
} from "assets";

export const Footer = ({
  disabled = false,
  totalAmount = 0,
  totalEmployees = 0,
  showMarkAsPaid = false,
  disabledMarkAsPaid = false,
  markAsPaidAction = () => {},
  markAsPendingAction = () => {},
  showWompi = true,
  disableWompi = false,
  openWompiModal = () => {},
  showDelete = false,
  deleteAction = () => {},
  showPayLater = false,
  disablePayLater = false,
  payLaterAction = () => {},
  showAskApproval = false,
  askApprovalAction = () => {},
  showApprove = false,
  approveAction = () => {},
  totalSelected = 0,
  section = "invoices",
  disabledApproval = false,
  disableDelete = false,
  showAmount = true,
  sendNotification = () => {},
  saveSettingsChanges = () => {},
  saveSettingsButtonIsDisable = false,
  cancelSettingsChanges = () => {},
  cancelButton = null,
  showAttentionModal = () => {},
  employeesWithoutAmount = false,
  disabledTooltip = false,
  footerDropdown = false,
  subscription,
  hasInvoicesPaidOutside = false,
  hasOneNegativeValue = false,
  showCausationButtons = false,
  hasOneMarkedAsCausation = false,
  hasOneToMarkAsCausation = false,
  openCausationMarkAsPendingModal = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const toggleDropdown = () => {
    setOpen((prevValue) => !prevValue);
  };

  const markButtonText = () => {
    return section === "collections"
      ? "Marcar como cobrada"
      : "Marcar como pagada";
  };

  const handleAction = (callback) => {
    if (subscription) {
      subscription.handleActionSubscription(callback);
    } else {
      callback();
    }
  };

  return (
    <footer
      style={
        isMobile
          ? section === "customer"
            ? styles.smallFooterMobile
            : styles.bigFooterMobile
          : styles.footer
      }
    >
      {isMobile ? (
        <div style={styles.footerElementsMobile}>
          <Stack direction={"column"} alignContent={"center"} spacing={2}>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              {showDelete && section === "payroll" && (
                <RedBorderButton
                  isDisable={disabled}
                  text={"Eliminar"}
                  action={deleteAction}
                  width={170}
                />
              )}
              {showPayLater && section === "payroll" && (
                <SecondaryButton
                  isDisable={disabled || disablePayLater || disabledApproval}
                  text={"Agrupar"}
                  action={payLaterAction}
                  width={170}
                />
              )}
              {showMarkAsPaid && (
                <Tooltip
                  title={
                    disabledTooltip
                      ? "El rol contador no permite realizar esta acción"
                      : ""
                  }
                >
                  <span>
                    <ThirdButton
                      isDisable={
                        disabled || disabledMarkAsPaid || disabledApproval
                      }
                      text={markButtonText()}
                      action={markAsPaidAction}
                      width={358}
                    />
                  </span>
                </Tooltip>
              )}
            </Stack>
            {section === "collections" && (
              <PrimaryButton
                isDisable={disabled || disableWompi || disabledApproval}
                text={"Enviar notificación de cobros"}
                action={sendNotification}
                width={358}
              />
            )}
            {showWompi && (
              <div>
                <Tooltip
                  title={
                    disabledTooltip
                      ? "El rol contador no permite realizar esta acción"
                      : ""
                  }
                >
                  <span>
                    <PrimaryButton
                      isDisable={
                        disabled ||
                        disableWompi ||
                        disabledApproval ||
                        hasInvoicesPaidOutside ||
                        hasOneNegativeValue
                      }
                      text={
                        isMobile
                          ? `Pagar ${formatAmount.format(totalAmount)}`
                          : "Pagar ahora"
                      }
                      action={() =>
                        handleAction(
                          section === "payroll" && employeesWithoutAmount
                            ? showAttentionModal
                            : openWompiModal
                        )
                      }
                      width={358}
                      style={{ marginTop: "2px" }}
                    />
                  </span>
                </Tooltip>
              </div>
            )}
            {!disabled &&
              (section === "invoices" || section === "customer") && (
                <p style={styles.footerSubTextMobile}>
                  {totalSelected}{" "}
                  {totalSelected === 1 ? "seleccionada" : "seleccionadas"}
                </p>
              )}
          </Stack>
        </div>
      ) : section === "customer" ? (
        <div className="customer-layout-container">
          <div style={styles.receiversFooterElements}>
            <Stack direction={"row"} alignContent={"center"} spacing={2}>
              {!disabled && (
                <div style={styles.container}>
                  <p style={styles.footerText}>
                    Monto a pagar:{" $"}
                    <NumericFormat
                      value={parseInt(totalAmount)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                    />
                  </p>
                  <p style={styles.footerSubText}>
                    {totalSelected}{" "}
                    {totalSelected === 1
                      ? "factura seleccionada"
                      : "facturas seleccionadas"}
                  </p>
                </div>
              )}
              {showWompi && (
                <PrimaryButton
                  isDisable={
                    disabled ||
                    disableWompi ||
                    disabledApproval ||
                    hasInvoicesPaidOutside ||
                    hasOneNegativeValue
                  }
                  text={"Pagar ahora"}
                  action={() => handleAction(openWompiModal)}
                  width={120}
                  style={{ marginTop: "2px" }}
                />
              )}
            </Stack>
          </div>
        </div>
      ) : (
        <div className="layout-container">
          <div style={styles.footerElements}>
            <Stack direction={"row"} alignContent={"center"} spacing={2}>
              {footerDropdown && !subscription?.isPayanaInvoice ? (
                <Tooltip
                  title={
                    disabledTooltip
                      ? "El rol contador no permite realizar esta acción"
                      : ""
                  }
                >
                  <Dropdown
                    onToggle={() => {
                      toggleDropdown();
                    }}
                  >
                    <span>
                      <DropdownButton
                        text="Más acciones"
                        disabled={disabled}
                        open={open}
                        openedIcon={<ChevronUpIcon />}
                        closedIcon={<ChevronDownIcon />}
                        style={{
                          width: "148px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: "8px 12px 8px 12px",
                          alignItems: "center",
                        }}
                      />
                    </span>
                    <Dropdown.Menu style={styles.noPadding}>
                      <Dropdown.Item
                        style={styles.dropdownPadding}
                        onClick={() => {
                          deleteAction();
                        }}
                        disabled={
                          disabled ||
                          disableDelete ||
                          subscription?.isPayanaInvoice
                        }
                      >
                        <div
                          style={styles.dropdownItemContainer}
                          type="dropdown"
                        >
                          <DeleteIcon
                            height={"20px"}
                            width={"20px"}
                            stroke={"#B42318"}
                          />
                          <div style={styles.dropdownItemTextContainer}>
                            <p style={styles.dropdownItemDeleteText}>
                              Eliminar
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Divider />
                      {showApprove && (
                        <>
                          <Dropdown.Item
                            style={styles.dropdownPadding}
                            onClick={() => {
                              approveAction();
                            }}
                          >
                            <div
                              style={styles.dropdownItemContainer}
                              type="dropdown"
                            >
                              <CheckDoneIcon height={"20px"} width={"20px"} />
                              <div style={styles.dropdownItemTextContainer}>
                                <p style={styles.dropdownItemText}>Aprobar</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Divider />
                        </>
                      )}
                      {showCausationButtons && hasOneToMarkAsCausation && (
                        <>
                          <Dropdown.Item
                            style={styles.dropdownPadding}
                            onClick={() => {
                              openCausationMarkAsPendingModal(
                                causationMarkAs.mark
                              );
                            }}
                          >
                            <div
                              style={styles.dropdownItemContainer}
                              type="dropdown"
                            >
                              <SuccessIcon stroke={"#344054"} />
                              <div style={styles.dropdownItemTextContainer}>
                                <p style={styles.dropdownItemText}>
                                  Marcar como causado
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Divider />
                        </>
                      )}
                      {showCausationButtons && hasOneMarkedAsCausation && (
                        <>
                          <Dropdown.Item
                            style={styles.dropdownPadding}
                            onClick={() => {
                              openCausationMarkAsPendingModal(
                                causationMarkAs.unmark
                              );
                            }}
                          >
                            <div
                              style={styles.dropdownItemContainer}
                              type="dropdown"
                            >
                              <RollbackIcon />
                              <div style={styles.dropdownItemTextContainer}>
                                <p style={styles.dropdownItemText}>
                                  Desmarcar como causado
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Divider />
                        </>
                      )}
                      {showMarkAsPaid && (
                        <>
                          <Dropdown.Item
                            style={styles.dropdownPadding}
                            onClick={() => {
                              markAsPaidAction();
                            }}
                            disabled={subscription?.isPayanaInvoice}
                          >
                            <div
                              style={styles.dropdownItemContainer}
                              type="dropdown"
                            >
                              <BankNoteIcon height={"20px"} width={"20px"} />
                              <div style={styles.dropdownItemTextContainer}>
                                <p style={styles.dropdownItemText}>
                                  {markButtonText()}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Divider />
                        </>
                      )}
                      {showMarkAsPaid &&
                        section === "invoices" &&
                        hasInvoicesPaidOutside && (
                          <>
                            <Dropdown.Item
                              style={styles.dropdownPadding}
                              onClick={() => {
                                markAsPendingAction();
                              }}
                              disabled={subscription?.isPayanaInvoice}
                            >
                              <div
                                style={styles.dropdownItemContainer}
                                type="dropdown"
                              >
                                <ReturnPendingIcon
                                  height={"24px"}
                                  width={"20px"}
                                />
                                <div style={styles.dropdownItemTextContainer}>
                                  <p style={styles.dropdownItemText}>
                                    Desmarcar como pagada
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <Divider />
                          </>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Tooltip>
              ) : (
                !subscription?.isPayanaInvoice && (
                  <>
                    {showAskApproval && (
                      <ThirdButton
                        isDisable={disabled}
                        text={"Pedir aprobación"}
                        action={askApprovalAction}
                        width={160}
                      />
                    )}
                    {showApprove && (
                      <ThirdButton
                        isDisable={disabled}
                        text={"Aprobar"}
                        action={approveAction}
                      />
                    )}
                    {showMarkAsPaid && (
                      <Tooltip
                        title={
                          disabledTooltip
                            ? "El rol contador no permite realizar esta acción"
                            : ""
                        }
                      >
                        <span>
                          <ThirdButton
                            isDisable={
                              disabled || disabledMarkAsPaid || disabledApproval
                            }
                            text={markButtonText()}
                            action={markAsPaidAction}
                            width={200}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {showDelete && (
                      <Tooltip
                        title={
                          disabledTooltip
                            ? "El rol contador no permite realizar esta acción"
                            : ""
                        }
                      >
                        <span>
                          <RedBorderButton
                            isDisable={disabled || disableDelete}
                            text="Eliminar"
                            action={deleteAction}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </>
                )
              )}
            </Stack>
            {showAmount && (
              <Stack direction={"row"} alignContent={"center"} spacing={2}>
                {!disabled && (
                  <div style={styles.container}>
                    <p style={styles.footerText}>
                      {section === "collections" ||
                      (section === "payroll" &&
                        isUserAllowedTo(permissions.PAYROLLS_CREATE))
                        ? "Total:"
                        : "Monto a pagar:"}
                      {" $"}
                      <NumericFormat
                        value={parseInt(totalAmount)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                      />
                    </p>
                    {(section === "invoices" || section === "collections") && (
                      <p style={styles.footerSubText}>
                        {totalSelected}{" "}
                        {totalSelected === 1 ? "seleccionada" : "seleccionadas"}
                      </p>
                    )}
                    {section === "payroll" && (
                      <p style={styles.footerSubText}>
                        {totalEmployees}{" "}
                        {totalEmployees === 1 ? "empleado" : "empleados"}
                      </p>
                    )}
                  </div>
                )}
                {showPayLater && (
                  <Tooltip
                    title={
                      disabledTooltip
                        ? "El rol contador no permite realizar esta acción"
                        : ""
                    }
                  >
                    <span>
                      <SecondaryButton
                        isDisable={
                          disabled ||
                          disablePayLater ||
                          disabledApproval ||
                          subscription?.isPayanaInvoice ||
                          disabledApproval ||
                          hasOneNegativeValue
                        }
                        text={"Pagar después"}
                        action={
                          section === "payroll" && employeesWithoutAmount
                            ? showAttentionModal
                            : payLaterAction
                        }
                        width={""}
                      />
                    </span>
                  </Tooltip>
                )}
                {showWompi && (
                  <Tooltip
                    title={
                      disabledTooltip
                        ? "El rol contador no permite realizar esta acción"
                        : ""
                    }
                  >
                    <span>
                      <PrimaryButton
                        icon={
                          subscription?.showIcon ? (
                            <CrownIcon size="small" fill="#EFA83C" />
                          ) : null
                        }
                        isDisable={
                          disabled ||
                          disableWompi ||
                          disabledApproval ||
                          hasInvoicesPaidOutside ||
                          hasOneNegativeValue
                        }
                        text={"Pagar ahora"}
                        action={() =>
                          handleAction(
                            section === "payroll" && employeesWithoutAmount
                              ? showAttentionModal
                              : openWompiModal
                          )
                        }
                        width={""}
                        style={{ marginTop: "2px" }}
                      />
                    </span>
                  </Tooltip>
                )}
                {section === "collections" && (
                  <PrimaryButton
                    icon={
                      subscription?.showIcon ? (
                        <CrownIcon size="small" fill="#EFA83C" />
                      ) : null
                    }
                    isDisable={disabled || disableWompi || disabledApproval}
                    text={"Enviar notificación de cobros"}
                    action={() =>
                      subscription.handleActionSubscription(sendNotification)
                    }
                    width={subscription?.showIcon ? 250 : 220}
                  />
                )}
              </Stack>
            )}
            {section === "settings" && (
              <Stack direction={"row"} alignContent={"flex-end"} spacing={2}>
                {cancelButton && (
                  <SecondaryButton
                    text={"Cancelar"}
                    action={cancelSettingsChanges}
                    width={92}
                  />
                )}
                <PrimaryButton
                  text={"Guardar cambios"}
                  action={saveSettingsChanges}
                  width={147}
                  isDisable={saveSettingsButtonIsDisable}
                />
              </Stack>
            )}
          </div>
        </div>
      )}
    </footer>
  );
};
