import React, { useContext, useEffect, useState } from "react";
import * as ReconciliationsService from "../../../services/api/reconciliations/Reconciliations.service";
import { Typography } from "@mui/material";
import { SecondaryButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ArrowLeftIcon, ChevronLeftIcon, RightArrowIcon } from "assets";
import { styles } from "../styles";
import { ReconciliationTabs } from "./ReconciliationTabs";
import { BankMovementItem } from "./BankMovementItem";
import { objectIsEmpty } from "utils/objectUtils";
import { PayanaReceiptItem } from "./PayanaReceiptItem";
import { BankMovementsFilters } from "./BankMovementsFilters";
import { MovementsEmptyState } from "./MovementsEmptyState";
import { TableSkeleton } from "commons";
import { ReconciliationsContext } from "contexts";
import { format, parse } from "date-fns";
import { BankMovementsFiltersModal } from "./BankMovementsFiltersModal";

export const BankMovements = ({
  bankMovements,
  selectedMovement,
  setSelectedMovement,
  setPageIndex,
  reconciliationOption,
  setReconciliationOption,
  setStatusSelected,
  date,
  setDate,
  selectedDate,
  setSelectedDate,
  type,
  setType,
  setTabSelected,
  setAccountingType,
  setPerPageSelected,
  setSelectedCostCenter,
  payanaReceipts,
  setPayanaReceipts,
  resetAccounting,
  setReconciledData,
  amountSignSelected,
  setAmountSignSelected,
  totalPages,
  pageIndex,
  pageLoading,
  setPageLoading,
  setMovementId,
  selectedBank,
  setCoincidences,
  setLoadingSupplierItems,
  setPayanaMovement,
  accountingOption,
  setItems,
  description,
  setDescription,
  isListLoading,
  refreshBankMovementsTable,
}) => {
  const { movementAlias, setMovementAlias } = useContext(
    ReconciliationsContext
  );

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (description !== undefined) {
        refreshBankMovementsTable({ firstLoading: false });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [description]);

  const handleSelectMovement = (movement) => {
    resetAccounting();

    if (
      (movement.status === "reconciled" ||
        movement.reconciliationStatus === "reconciled") &&
      !movement.is_from_payana
    ) {
      setSelectedMovement(movement);
      setReconciledData(movement?.related_receipts);
    } else if (movement.is_from_payana) {
      setPageLoading(true);
      setMovementAlias(movement.description);
      setMovementId(movement.id);
      setPayanaMovement(movement);
      setReconciledData([]);
      ReconciliationsService.getPayanaMovementReceipts(
        movement.amount,
        movement.issue_date
      ).then((res) => {
        const transactions = res?.transactions;
        setPayanaReceipts([
          ...transactions,
          {
            id: res?.commissionReceipts.id,
            hash: res?.commissionReceipts.hash,
            createdAt: res?.commissionReceipts.created_at,
            amount: -res?.commissionReceipts.comission_net_amount,
            transactionType: "commission",
            reconciliationMovementType: "TRANSACTION_COMISSION",
            transactionId: res?.commissionReceipts.transaction_id,
            reconciliationStatus: res?.commissionReceipts.reconciliation_status,
            related_receipts: res?.commissionReceipts.related_receipts,
            related_movements: res?.commissionReceipts.related_movements,
            reconciliationSetId: res?.commissionReceipts.reconciliation_set_id,
          },
        ]);
        setSelectedMovement({});
        setPageLoading(false);
      });
    } else {
      const formattedDate = format(
        movement.issue_date
          ? parse(movement.issue_date, "dd/MM/yyyy", new Date())
          : new Date(movement.createdAt),
        "yyyy-MM-dd"
      );

      setLoadingSupplierItems(true);
      setReconciledData([]);

      Promise.all([
        ReconciliationsService.findCoincidences({
          reconciliationBankId: selectedBank.id,
          amount:
            movement?.reconciliationMovementType === "BANK_MOVEMENT" ||
            movement?.transactionType === "collection" ||
            movement?.transactionType === "commission"
              ? movement?.amount
              : -movement?.amount,
          issueDate: formattedDate,
          description: movement.description,
        }),
        accountingOption === "reconcile" &&
          ReconciliationsService.getAccountingReceipts({
            reconciliationBankId: selectedBank.id,
            status: "not_reconciled",
          }),
      ]).then(([coincidencesRes, receiptsRes]) => {
        setCoincidences(coincidencesRes);
        if (receiptsRes && receiptsRes?.response?.status !== 400) {
          setItems(receiptsRes);
        }
        setLoadingSupplierItems(false);
        setSelectedMovement(movement);
      });
    }
  };

  return (
    <div style={styles.bankMovementsContainer}>
      <div style={styles.bankMovementsTitleContainer}>
        <Typography sx={styles.bankMovementsTitle} noWrap>
          Movimientos de dinero
        </Typography>
      </div>
      {objectIsEmpty(payanaReceipts) ? (
        <>
          <ReconciliationTabs
            optionSelected={reconciliationOption}
            setOptionSelected={setReconciliationOption}
            setStatusSelected={setStatusSelected}
            setSelectedMovement={setSelectedMovement}
            setTabSelected={setTabSelected}
            setAccountingType={setAccountingType}
            setSelectedCostCenter={setSelectedCostCenter}
          />
          {!pageLoading && (
            <BankMovementsFilters
              date={date}
              setDate={setDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setPageIndex={setPageIndex}
              type={type}
              setType={setType}
              amountSignSelected={amountSignSelected}
              setAmountSignSelected={setAmountSignSelected}
              setPerPageSelected={setPerPageSelected}
              description={description}
              setDescription={setDescription}
              setIsFiltersModalVisible={setIsFiltersModalVisible}
            />
          )}
        </>
      ) : (
        <>
          <div
            style={styles.backContainer}
            onClick={() => {
              setPayanaReceipts([]);
              setSelectedMovement({});
            }}
          >
            <ChevronLeftIcon color="#5925DC" width="20px" height="20px" />
            <Typography style={styles.backText}>Volver</Typography>
          </div>
          <div style={styles.movementAliasContainer}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "600", color: "#475467" }}
            >
              {movementAlias}
            </Typography>
          </div>
        </>
      )}
      <div style={styles.movementsContainer}>
        {isListLoading ? (
          <div style={{ width: "100%", marginBottom: "16px" }}>
            <TableSkeleton />
          </div>
        ) : pageLoading ? (
          <div
            className="fade-in-with-translation"
            style={{ width: "100%", marginBottom: "16px" }}
          >
            <TableSkeleton />
          </div>
        ) : objectIsEmpty(bankMovements) ? (
          <MovementsEmptyState reconciliationOption={reconciliationOption} />
        ) : (
          <>
            {payanaReceipts.length > 0
              ? payanaReceipts.map((movement) => (
                  <PayanaReceiptItem
                    key={movement.id}
                    selectedMovement={selectedMovement}
                    movement={{
                      ...movement,
                      reconciliationMovementType:
                        movement.reconciliationMovementType ||
                        "TRANSACTION_RECEIPT",
                    }}
                    setSelectedMovement={setSelectedMovement}
                    handleSelectMovement={handleSelectMovement}
                    setPayanaReceipts={setPayanaReceipts}
                  />
                ))
              : bankMovements.map((movement) => (
                  <BankMovementItem
                    key={movement.id}
                    selectedMovement={selectedMovement}
                    movement={{
                      ...movement,
                      reconciliationMovementType: "BANK_MOVEMENT",
                    }}
                    setSelectedMovement={setSelectedMovement}
                    handleSelectMovement={handleSelectMovement}
                  />
                ))}
          </>
        )}
      </div>
      {!objectIsEmpty(bankMovements) && !pageLoading && (
        <div className="paginationNav" style={{ marginBottom: "8px" }}>
          <span style={styles.paginationFont}>
            Página <strong>{pageIndex}</strong> de <strong>{totalPages}</strong>
          </span>
          <SecondaryButton
            isDisable={pageIndex <= 1}
            action={() => {
              setPageIndex((prev) => prev - 1);
            }}
            icon={
              <ArrowLeftIcon color={pageIndex <= 1 ? "#EAECF0" : "#344054"} />
            }
            width={40}
            style={{ padding: 0 }}
          />
          <SecondaryButton
            isDisable={pageIndex >= totalPages}
            action={() => {
              setPageIndex((prev) => prev + 1);
            }}
            icon={
              <RightArrowIcon
                color={pageIndex >= totalPages ? "#EAECF0" : "#344054"}
              />
            }
            width={40}
            style={{ padding: 0 }}
          />
        </div>
      )}
      <BankMovementsFiltersModal
        visible={isFiltersModalVisible}
        setIsFiltersModalVisible={setIsFiltersModalVisible}
        date={date}
        setDate={setDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        type={type}
        setType={setType}
        amountSignSelected={amountSignSelected}
        setAmountSignSelected={setAmountSignSelected}
        setPageIndex={setPageIndex}
      />
    </div>
  );
};
