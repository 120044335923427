import React, { useState, useEffect, useContext, useRef } from "react";
import { TrackJS } from "trackjs";
import * as ReconciliationsService from "../../services/api/reconciliations/Reconciliations.service";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  TableSkeleton,
  ButtonWithIcon,
  SiigoIntegrationModal,
  BankExtractModal,
  MarkAsReconciledModal,
  ReconciliationsTrialModal,
  PartiallyReconciledDetailModal,
  ReconcileFiltersModal,
  EndPeriodModal,
  NewPeriodModal,
  DeleteItemModal,
} from "../../commons";
import {
  formatLocaleDate,
  getFromLocalStorage,
  saveInLocalStorage,
} from "../../utils";
import { styles } from "./styles";

import { ReconciliationsContext, UserContext } from "../../contexts";
import { ChevronLeftIcon } from "../../assets";
import { useAssistedExpenses, useChat } from "../../hooks";
import {
  Accounting,
  BankMovements,
  Banks,
  ReconciliationsEmptyState,
} from "./components";
import { addDays, addYears, format } from "date-fns";

import { toast } from "react-toastify";
import { toastOptions } from "constants";
import CsvHandler from "commons/modals/UploadFileModal/CsvHandler";
import { formatDate } from "commons/modals/ExpandAssistedExpenses/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { PrimaryButton } from "commons/buttons";
import { SeeMoreDropdown } from "./components/SeeMoreDropdown";

export const ReconciliationsScreen = (props) => {
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const {
    internalGetBankMovements,
    reconciliationsScreen,
    statusSelected,
    setStatusSelected,
    perPageSelected,
    setPerPageSelected,
    amountSignSelected,
    setAmountSignSelected,
    bankMovements,
    selectedBank,
    setSelectedBank,
    banks,
    setBanks,
    selectedProcess,
    setSelectedProcess,
    selectedProcessId,
    setSelectedProcessId,
    activeProcesses,
    setActiveProcesses,
  } = useContext(ReconciliationsContext);
  const { getMe, isReconciliationsEnabled, hasSiigoCredentials } =
    useContext(UserContext);

  const location = useLocation();

  const pageIndex = reconciliationsScreen?.pageIndex;
  const setPageIndex = reconciliationsScreen?.setPageIndex;

  const beneficiaryIdSelected =
    reconciliationsScreen?.beneficiaryIdSelected?.value;
  const setBeneficiaryIdSelected =
    reconciliationsScreen?.beneficiaryIdSelected?.setter;

  const [reconciliationOption, setReconciliationOption] =
    useState("not_reconciled");

  const [accountingOption, setAccountingOption] = useState("reconcile");
  const [accountingType, setAccountingType] = useState("paymentRecord");

  const [searchOptions, setSearchOptions] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: addYears(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState("all");
  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);

  const startDate = date.at(0).startDate
    ? format(date.at(0).startDate, "yyyy-MM-dd")
    : "all";
  const endDate = date.at(0).endDate
    ? format(date.at(0).endDate, "yyyy-MM-dd")
    : "all";

  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [selectedMovement, setSelectedMovement] = useState({});
  const [isSiigoModalVisible, setIsSiigoModalVisible] = useState(false);
  const [isBankExtractModalVisible, setIsBankExtractModalVisible] =
    useState(false);
  const [
    isPartiallyReconciledDetailModalVisible,
    setIsPartiallyReconciledDetailModalVisible,
  ] = useState(false);
  const [isEndPeriodModalVisible, setIsEndPeriodModalVisible] = useState(false);
  const [isNewPeriodModalVisible, setIsNewPeriodModalVisible] = useState(false);
  const [isReconcileFiltersModalVisible, setIsReconcileFiltersModalVisible] =
    useState(false);
  const [isMarkAsReconciledModalVisible, setIsMarkAsReconciledModalVisible] =
    useState(false);
  const [showNewReconciliation, setShowNewReconciliation] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [description, setDescription] = useState("");

  const reconciliationsCsvButton = useRef(null);

  const { items, setItems } = useAssistedExpenses({
    isAssistedEgress: false,
    movementAmount:
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount,
  });

  const [costCenters, setCostCenters] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loadingSupplierItems, setLoadingSupplierItems] = useState(false);
  const [payanaReceipts, setPayanaReceipts] = useState([]);
  const [comment, setComment] = useState("");
  const [reconciledData, setReconciledData] = useState([]);
  const [showSiigoIntermittencies, setShowSiigoIntermittencies] =
    useState(false);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [trialModalIsVisible, setTrialModalIsVisible] = useState(
    getFromLocalStorage("isTrialModalVisible") || "true"
  );
  const [movementId, setMovementId] = useState(null);
  const [payanaMovement, setPayanaMovement] = useState(null);
  const [coincidences, setCoincidences] = useState([]);
  const [beneficiaryName, setBeneficiaryName] = useState(null);
  const [reconciliationProcessToDelete, setReconciliationProcessToDelete] =
    useState(null);
  const [
    deleteReconciliationProcessModalIsVisible,
    setDeleteReconciliationProcessModalIsVisible,
  ] = useState(false);

  const [unrenconcileSet, setUnreconcileSet] = useState(null);
  const [isUnreconcileModalVisible, setUnreconcileModalVisible] =
    useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  useChat();

  const navigate = useNavigate();

  const refreshBankMovementsTable = async ({ firstLoading = false }) => {
    try {
      firstLoading ? setPageLoading(true) : setIsListLoading(true);
      if (selectedProcessId && banks.length > 0) {
        Promise.all([
          ReconciliationsService.getReconciliationsToken(),
          ReconciliationsService.getProcess(selectedProcessId),
          ReconciliationsService.getBankMovementSuppliers(),
          checkSiigoCredentials(),
        ]).then(([tokenRes, processRes, suppliersRes, siigoCredentialsRes]) => {
          if (tokenRes?.response?.status !== 400) {
            setShowSiigoIntermittencies(true);
          }

          if (processRes?.response?.status !== 400) {
            setSelectedProcess(processRes.process);
          } else {
            setSelectedProcess(null);
          }

          if (suppliersRes?.response?.status === 400) {
            setSearchOptions([]);
            setShowSiigoIntermittencies(true);
          } else {
            setSearchOptions(suppliersRes);
            setShowSiigoIntermittencies(false);
          }

          if (siigoCredentialsRes) {
            setSyncCS((prev) => ({ ...prev, siigo: true }));
          }
        });

        await internalGetBankMovements(
          pageIndex,
          statusSelected,
          startDate,
          endDate,
          perPageSelected,
          amountSignSelected,
          selectedProcessId,
          description
        );

        const totalBankMovements =
          await ReconciliationsService.GetBankMovementsTotal({
            status: statusSelected,
            startDate: startDate,
            endDate: endDate,
            amountSign: amountSignSelected,
            processId: selectedProcessId,
            description: description,
          });
        setTotal(totalBankMovements);
      }
    } catch (error) {
      TrackJS.console.error(error);
    } finally {
      firstLoading ? setPageLoading(false) : setIsListLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    if (!hasSiigoCredentials) {
      navigate("/payments", { replace: true });
    }
  }, [hasSiigoCredentials]);

  useEffect(() => {
    navigate("/payments", { replace: true });
    // getMe().finally(() => {
    //   if (!isReconciliationsEnabled) {
    //     navigate("/reconciliations/coming-soon", { replace: true });
    //   }
    // });

    // if (reconciliationOption !== "not_reconciled") {
    //   setReconciliationOption("not_reconciled");
    //   setStatusSelected("not_reconciled");
    // }

    // Promise.all([
    //   ReconciliationsService.getActiveProcesses(),
    //   ReconciliationsService.getReconciliationsBanks(),
    // ]).then(([activeProcesses, reconciliationsBanks]) => {
    //   setActiveProcesses(activeProcesses.processes);
    //   setBanks(reconciliationsBanks);
    //   setFirstLoading(false);
    // });
  }, []);

  useEffect(() => {
    refreshBankMovementsTable({ firstLoading: true }).then(() => {
      setIsPageInitialLoading(false);
    });
  }, [firstLoading]);

  useEffect(() => {
    if (!isPageInitialLoading) {
      refreshBankMovementsTable({ firstLoading: false });
    }
  }, [
    pageIndex,
    selectedDate,
    amountSignSelected,
    statusSelected,
    selectedBank,
    perPageSelected,
    selectedProcessId,
    description,
  ]);

  useEffect(() => {
    Promise.all([
      ReconciliationsService.getActiveProcesses(),
      ReconciliationsService.getReconciliationsBanks(),
    ]).then(([activeProcesses, reconciliationsBanks]) => {
      setActiveProcesses(activeProcesses.processes);
      setBanks(reconciliationsBanks);
      setFirstLoading(false);
      setIsRefreshing(false);
    });
  }, [isRefreshing]);

  useEffect(() => {
    if (location.state && location.state.importSuccess) {
      Promise.all([
        ReconciliationsService.getActiveProcesses(),
        ReconciliationsService.getReconciliationsBanks(),
      ]).then(([activeProcesses, reconciliationsBanks]) => {
        setActiveProcesses(activeProcesses.processes);
        setBanks(reconciliationsBanks);
        setFirstLoading(false);
        setShowNewReconciliation(false);
        setShowTrialModal(true);
        toast.success("¡Conciliación agregada con éxito!", toastOptions);
      });
    }
  }, [location]);

  useEffect(() => {
    if (totals) {
      if (totals.total > perPageSelected) {
        setTotalPages(Math.ceil(parseInt(totals.total) / perPageSelected));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const getSearchOptions = (query) => {
    ReconciliationsService.getBankMovementSuppliers(query).then((res) => {
      if (res?.response?.status === 400) {
        setSearchOptions([]);
        setShowSiigoIntermittencies(true);
      } else {
        setSearchOptions(res);
        setShowSiigoIntermittencies(false);
      }
    });
  };

  const getPaymentMethodOptions = (query) => {
    ReconciliationsService.getPaymentMethods(query, accountingType).then(
      (res) => {
        setPaymentMethods(res);
      }
    );
  };

  const closeDeleteProcessModal = () => {
    setReconciliationProcessToDelete(null);
    setDeleteReconciliationProcessModalIsVisible(false);
  };

  const closeUnreconcileModal = () => {
    setUnreconcileSet(null);
    setUnreconcileModalVisible(false);
  };

  const handleSearchChange = (beneficiary) => {
    if (beneficiary) {
      setLoadingSupplierItems(true);
      setBeneficiaryIdSelected(beneficiary.AccountID);
      reconciliationsScreen?.beneficiaryFilter?.setter(beneficiary);
      let tmpBeneficiaryDues = [];

      if (accountingOption === "register") {
        const bankData = JSON.parse(
          getFromLocalStorage("reconciliationsBankData")
        );

        Promise.all([
          ReconciliationsService.getSupplierDues(
            beneficiary.AccountID,
            accountingType
          ),
          ReconciliationsService.getCostCenters(),
          ReconciliationsService.getDocuments(accountingType),
          ReconciliationsService.getPaymentMethods(
            bankData.erpExternalAccount,
            accountingType
          ),
        ])
          .then(
            ([
              supplierDuesRes,
              costCentersRes,
              documentsRes,
              paymentMethodsRes,
            ]) => {
              if (supplierDuesRes?.response?.status !== 400) {
                supplierDuesRes?.map((item, index) =>
                  tmpBeneficiaryDues.push({
                    ...item,
                    id: index,
                    amountRegister: 0,
                    DueDate: formatDate(item.DueDate),
                  })
                );
              }

              setItems(tmpBeneficiaryDues);
              setCostCenters(
                costCentersRes?.response?.status === 400 ? [] : costCentersRes
              );
              setDocumentTypes(
                documentsRes?.response?.status === 400 ? [] : documentsRes
              );
              setPaymentMethods(
                paymentMethodsRes?.response?.status === 400
                  ? []
                  : paymentMethodsRes
              );
            }
          )
          .finally(() => {
            setLoadingSupplierItems(false);
          });
      } else {
        setBeneficiaryName(beneficiary.FullName);
        ReconciliationsService.getAccountingReceipts({
          reconciliationBankId: selectedBank.id,
          status: "not_reconciled",
          thirdName: beneficiary.FullName,
        })
          .then((res) => {
            if (res?.response?.status !== 400) {
              setItems(res);
            }
          })
          .finally(() => {
            setLoadingSupplierItems(false);
          });
      }
    } else {
      if (accountingOption === "reconcile") {
        ReconciliationsService.getAccountingReceipts({
          reconciliationBankId: selectedBank.id,
          status: "not_reconciled",
        }).then((res) => {
          if (res && res?.response?.status !== 400) {
            setItems(res);
          }
        });
      }
      setBeneficiaryIdSelected("all");
      reconciliationsScreen?.beneficiaryFilter?.setter({});
    }
    setPageIndex(1);
  };

  const checkSiigoCredentials = async () => {
    try {
      const resp = await ReconciliationsService.checkSiigoCredentials();
      const syncValidation = resp?.syncValidation;
      return syncValidation?.credentials;
    } catch (error) {
      toast.error(
        "Hubo un error al obtener la información de credenciales",
        toastOptions
      );
    }
  };

  const refreshPayanaMovementReceipts = () => {
    ReconciliationsService.getPayanaMovementReceipts(
      payanaMovement.amount,
      payanaMovement.issue_date
    ).then((res) => {
      const transactions = res?.transactions;
      setPayanaReceipts([
        ...transactions,
        {
          id: res?.commissionReceipts.id,
          hash: res?.commissionReceipts.hash,
          createdAt: res?.commissionReceipts.created_at,
          amount: -res?.commissionReceipts.comission_net_amount,
          transactionType: "commission",
          reconciliationMovementType: "TRANSACTION_COMISSION",
          transactionId: res?.commissionReceipts.transaction_id,
          reconciliationStatus: res?.commissionReceipts.reconciliation_status,
          related_receipts: res?.commissionReceipts.related_receipts,
          related_movements: res?.commissionReceipts.related_movements,
          reconciliationSetId: res?.commissionReceipts.reconciliation_set_id,
        },
      ]);
    });
  };

  const getBankMovementId = {
    BANK_MOVEMENT: selectedMovement.id,
    TRANSACTION_RECEIPT: movementId,
    TRANSACTION_COMISSION: movementId,
  };

  const handleUnreconcile = async () => {
    const res = await ReconciliationsService.unrenconcileSet({
      reconciliationSetId:
        selectedMovement.reconciliation_set_id ||
        selectedMovement.reconciliationSetId,
      reconciliationMovementType: selectedMovement.reconciliationMovementType,
      bankMovementId:
        getBankMovementId[selectedMovement?.reconciliationMovementType],
    });

    setUnreconcileModalVisible(false);
    setUnreconcileSet(null);

    if (res?.response && res.response.status !== 200) {
      toast.error("Hubo un error al desconciliar.", toastOptions);
    }

    selectedMovement.reconciliationSetId && refreshPayanaMovementReceipts();
    resetAccounting();
    setSelectedMovement({});
    setReconciliationOption("not_reconciled");
    setStatusSelected("not_reconciled");

    refreshBankMovementsTable();
  };

  const [syncCS, setSyncCS] = useState({
    siigo: false,
  });

  const checkCredentials = async () => {
    const haveCredentials = await checkSiigoCredentials();
    if (haveCredentials) {
      setSyncCS((prev) => ({ ...prev, siigo: true }));
    }
  };

  const handleSiigoCloseModal = async () => {
    setIsSiigoModalVisible(false);
    checkCredentials();
  };

  const deleteReconciliation = async () => {
    const res = await ReconciliationsService.deleteReconciliationProcess(
      reconciliationProcessToDelete
    );

    if (res?.response && res.response.status !== 200) {
      if (res.response.status === 400) {
        toast.error(
          "No puedes eliminar una conciliación con movimientos asignados.",
          toastOptions
        );
      } else {
        toast.error("Hubo un error eliminando la conciliación.", toastOptions);
      }
      setDeleteReconciliationProcessModalIsVisible(false);
      setReconciliationProcessToDelete(null);
      return;
    }

    setDeleteReconciliationProcessModalIsVisible(false);
    setReconciliationProcessToDelete(null);
    setIsRefreshing(true);
  };

  const emptyState =
    !pageLoading &&
    !firstLoading &&
    bankMovements &&
    banks.length === 0 &&
    activeProcesses.length === 0;

  const resetAccounting = () => {
    setAccountingOption("reconcile");
    setAccountingType("paymentRecord");
    reconciliationsScreen?.beneficiaryFilter?.setter({});
    setSelectedCostCenter(null);
    setComment("");
    setSelectedDocumentType(null);
    setSelectedPaymentMethod(null);
    setReconciledData([]);
    setCoincidences([]);
  };

  const renderProcess = (activeProcess) => {
    const { process, movementsMetrics, accountingReceiptsMetrics } =
      activeProcess;

    const bank = banks.find(
      (bank) => bank.id === process.reconciliation_bank_id
    );

    return (
      <div
        style={{
          width: "49%",
          minWidth: "49%",
          maxWidth: "49%",
          border: "1px solid #D0D5DD",
          borderRadius: "8px",
          padding: "16px",
          gap: "16px",
          marginBottom: "8px",
          boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {bank?.logo && (
              <img
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "2px",
                }}
                src={bank.logo}
                alt="bankLogo"
              />
            )}

            <Typography
              style={{
                fontSize: "18px",
                color: "#344054",
                textTransform: "capitalize",
                width: "100%",
                fontWeight: "600",
              }}
            >
              {bank?.bank_alias}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: process.status === "reconciled" ? "#344054" : "#027A48",
                fontWeight: "500",
              }}
            >
              {process.status === "reconciled"
                ? "Conciliación finalizada"
                : "Conciliación en curso"}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}
            >
              {formatLocaleDate(
                addDays(new Date(process.start_date), 1),
                "dd 'de' MMMM yyyy"
              )}{" "}
              al{" "}
              {formatLocaleDate(
                addDays(new Date(process.end_date), 1),
                "dd 'de' MMMM yyyy"
              )}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                gap: "4px",
                padding: "12px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#475467",
                }}
              >
                Saldo del estado de cuenta
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#101828",
                }}
              >
                <NumericFormat
                  value={process.end_balance}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"$"}
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#027A48",
                  }}
                >
                  {`Conciliado (${movementsMetrics?.reconciled_movements}): `}
                  <NumericFormat
                    value={movementsMetrics?.reconciled_movements_total}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix={"$"}
                  />
                </Typography>
              </div>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#1D2939",
                }}
              >
                {`No conciliado (${movementsMetrics?.not_reconciled_movements}): `}
                <NumericFormat
                  value={movementsMetrics?.not_reconciled_movements_total}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={
                    movementsMetrics?.not_reconciled_movements_total >= 0
                      ? "$"
                      : "-$"
                  }
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                gap: "4px",
                padding: "12px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#475467",
                }}
              >
                Balance contable
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#101828",
                }}
              >
                <NumericFormat
                  value={process.erp_end_balance}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"$"}
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#027A48",
                  }}
                >
                  {`Conciliado (${accountingReceiptsMetrics?.reconciled_accounting_receipts}): `}
                  <NumericFormat
                    value={
                      accountingReceiptsMetrics?.reconciled_accounting_receipts_total
                    }
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix={"$"}
                  />
                </Typography>
              </div>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#1D2939",
                }}
              >
                {`No conciliado (${accountingReceiptsMetrics?.not_reconciled_accounting_receipts}): `}
                <NumericFormat
                  value={
                    accountingReceiptsMetrics?.not_reconciled_accounting_receipts_total
                  }
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={
                    movementsMetrics?.not_reconciled_accounting_receipts_total >=
                    0
                      ? "$"
                      : "-$"
                  }
                />
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border: "1px solid #D0D5DD",
              borderRadius: "8px",
              gap: "4px",
              padding: "12px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#475467",
                alignSelf: "center",
              }}
            >
              Diferencia
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#B42318",
              }}
            >
              <NumericFormat
                value={process.end_balance - process.erp_end_balance}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                prefix={"$"}
              />
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "8px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <SeeMoreDropdown
              process={process}
              bank={bank}
              setSelectedProcessId={setSelectedProcessId}
              setSelectedBank={setSelectedBank}
              setIsEndPeriodModalVisible={setIsEndPeriodModalVisible}
              setIsNewPeriodModalVisible={setIsNewPeriodModalVisible}
              setIsRefreshing={setIsRefreshing}
              setDeleteReconciliationProcessModalIsVisible={
                setDeleteReconciliationProcessModalIsVisible
              }
              setReconciliationProcessToDelete={
                setReconciliationProcessToDelete
              }
            ></SeeMoreDropdown>
            <PrimaryButton
              text="Abrir"
              width="158px"
              action={() => {
                setSelectedProcessId(process.id);
                setSelectedBank(bank);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SiigoIntegrationModal
        visible={isSiigoModalVisible}
        setIsSiigoModalVisible={handleSiigoCloseModal}
        isReconciliations={true}
      />
      <CsvHandler
        licenseKey={process.env.REACT_APP_CSVBOX_RECONCILIATIONS_LICENSE_KEY}
        buttonRef={reconciliationsCsvButton}
        entity="reconciliations"
      />
      <BankExtractModal
        visible={isBankExtractModalVisible}
        setIsBankExtractModalVisible={setIsBankExtractModalVisible}
        reconciliationsCsvButton={reconciliationsCsvButton}
      />
      <PartiallyReconciledDetailModal
        visible={isPartiallyReconciledDetailModalVisible}
        setIsPartiallyReconciledDetailModalVisible={
          setIsPartiallyReconciledDetailModalVisible
        }
        movement={selectedMovement}
        reconciliationSet={{
          reconciliationSetId:
            selectedMovement.reconciliation_set_id ||
            selectedMovement.reconciliationSetId,
          reconciliationMovementType:
            selectedMovement.reconciliationMovementType,
          bankMovementId:
            getBankMovementId[selectedMovement?.reconciliationMovementType],
        }}
        setUnreconcileModalVisible={setUnreconcileModalVisible}
        setUnreconcileSet={setUnreconcileSet}
      />
      <EndPeriodModal
        visible={isEndPeriodModalVisible}
        setIsEndPeriodModalVisible={setIsEndPeriodModalVisible}
        setSelectedProcessId={setSelectedProcessId}
        setSelectedBank={setSelectedBank}
        bankMovements={bankMovements}
        setIsNewPeriodModalVisible={setIsNewPeriodModalVisible}
        selectedProcess={selectedProcess}
        setIsRefreshing={setIsRefreshing}
      />
      <NewPeriodModal
        visible={isNewPeriodModalVisible}
        setIsNewPeriodModalVisible={setIsNewPeriodModalVisible}
        setSelectedProcessId={setSelectedProcessId}
        setSelectedBank={setSelectedBank}
        selectedBank={selectedBank}
        selectedProcess={selectedProcess}
        reconciliationsCsvButton={reconciliationsCsvButton}
      />
      <ReconcileFiltersModal
        visible={isReconcileFiltersModalVisible}
        setIsReconcileFiltersModalVisible={setIsReconcileFiltersModalVisible}
        setItems={setItems}
        selectedBank={selectedBank}
        beneficiary={beneficiaryName}
        setLoadingSupplierItems={setLoadingSupplierItems}
      />
      <MarkAsReconciledModal
        visible={isMarkAsReconciledModalVisible}
        setIsMarkAsReconciledModalVisible={setIsMarkAsReconciledModalVisible}
        selectedMovement={selectedMovement}
        accountingType={accountingType}
        resetAccounting={resetAccounting}
        setSelectedMovement={setSelectedMovement}
        setReconciliationOption={setReconciliationOption}
        setStatusSelected={setStatusSelected}
      />
      <ReconciliationsTrialModal
        open={showTrialModal && trialModalIsVisible === "true"}
        onClose={() => {
          saveInLocalStorage("isTrialModalVisible", "false");
          setTrialModalIsVisible("false");
          setShowTrialModal(false);
        }}
      />
      <DeleteItemModal
        itemId={reconciliationProcessToDelete}
        action={deleteReconciliation}
        handleClose={closeDeleteProcessModal}
        title="¿Estás seguro que quieres borrar el último período de conciliación?"
        subtitle="Esta acción no se puede deshacer"
        visible={deleteReconciliationProcessModalIsVisible}
      />
      <DeleteItemModal
        itemId={true}
        action={handleUnreconcile}
        handleClose={closeUnreconcileModal}
        title="¿Estás seguro que quieres desconciliar estos registros?"
        subtitle="Esta acción no se puede deshacer"
        visible={isUnreconcileModalVisible}
        deleteButtonText={"Sí, desconciliar"}
      />
      <div
        className="layout-container"
        style={{
          maxHeight: "120vh",
          overflow: "auto",
          marginBottom: "40px",
        }}
      >
        {!emptyState && selectedProcess && selectedProcessId && (
          <div
            style={{
              ...styles.titleContainer,
              justifyContent: "flex-start",
              gap: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedProcessId(null);
              setSelectedBank(null);
              setPayanaReceipts([]);
              setSelectedMovement({});
            }}
          >
            <ChevronLeftIcon color="#5925DC" width="20px" height="20px" />
            <Typography style={styles.backText}>Volver</Typography>
          </div>
        )}
        {isPageInitialLoading || firstLoading || pageLoading ? (
          <div className="fade-in-with-translation">
            <TableSkeleton />
          </div>
        ) : emptyState || showNewReconciliation ? (
          <ReconciliationsEmptyState
            setIsSiigoModalVisible={setIsSiigoModalVisible}
            setIsBankExtractModalVisible={setIsBankExtractModalVisible}
            syncCS={syncCS}
            checkCredentials={checkCredentials}
            reconciliationsCsvButton={reconciliationsCsvButton}
            setShowNewReconciliation={setShowNewReconciliation}
          />
        ) : selectedProcessId ? (
          <div style={styles.pageContainer}>
            <Banks
              banks={banks}
              selectedBank={selectedBank}
              process={selectedProcess}
              setSelectedProcessId={setSelectedProcessId}
              setSelectedBank={setSelectedBank}
              selectedProcessId={selectedProcessId}
              setIsEndPeriodModalVisible={setIsEndPeriodModalVisible}
              refreshBankMovementsTable={refreshBankMovementsTable}
            />
            {selectedProcess && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <Accordion
                  sx={{
                    border: "1px solid #D0D5DD",
                    borderRadius: "32px",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#344054",
                    }}
                  >
                    Saldos y balances
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        gap: "4px",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#475467",
                        }}
                      >
                        Saldo del estado de cuenta
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#101828",
                        }}
                      >
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess.end_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#027A48",
                          }}
                        >
                          {`Conciliado (${selectedProcess.movementsMetrics.reconciled_movements}): `}
                          <NumericFormat
                            value={
                              selectedProcess.movementsMetrics
                                .reconciled_movements_total
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            prefix={
                              selectedProcess.movementsMetrics
                                .reconciled_movements_total >= 0
                                ? "$"
                                : "-$"
                            }
                          />
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#1D2939",
                          }}
                        >
                          {`No conciliado (${selectedProcess.movementsMetrics.not_reconciled_movements}): `}
                          <NumericFormat
                            value={
                              selectedProcess.movementsMetrics
                                .not_reconciled_movements_total
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            prefix={
                              selectedProcess.movementsMetrics
                                .not_reconciled_movements_total >= 0
                                ? "$"
                                : "-$"
                            }
                          />
                        </Typography>
                      </div>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#1D2939",
                        }}
                      >
                        Saldo inicial:{" "}
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess.start_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        gap: "4px",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#475467",
                        }}
                      >
                        Balance contable
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#101828",
                        }}
                      >
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess
                              .erp_end_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#027A48",
                          }}
                        >
                          {`Conciliado (${selectedProcess.accountingReceiptsMetrics.reconciled_accounting_receipts}): `}
                          <NumericFormat
                            value={
                              selectedProcess.accountingReceiptsMetrics
                                .reconciled_accounting_receipts_total
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            prefix={
                              selectedProcess.accountingReceiptsMetrics
                                .reconciled_accounting_receipts_total >= 0
                                ? "$"
                                : "-$"
                            }
                          />
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#1D2939",
                          }}
                        >
                          {`No conciliado (${selectedProcess.accountingReceiptsMetrics.not_reconciled_accounting_receipts}): `}
                          <NumericFormat
                            value={
                              selectedProcess.accountingReceiptsMetrics
                                .not_reconciled_accounting_receipts_total
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            prefix={
                              selectedProcess.accountingReceiptsMetrics
                                .not_reconciled_accounting_receipts_total >= 0
                                ? "$"
                                : "-$"
                            }
                          />
                        </Typography>
                      </div>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#1D2939",
                        }}
                      >
                        Saldo inicial:{" "}
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess
                              .erp_start_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        gap: "4px",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#475467",
                        }}
                      >
                        Diferencia
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#B42318",
                        }}
                      >
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess.end_balance -
                            selectedProcess.reconciliationProcess
                              .erp_end_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#B42318",
                        }}
                      >
                        Diferencia no conciliada:{" "}
                        <NumericFormat
                          value={
                            selectedProcess.movementsMetrics
                              .not_reconciled_movements_total -
                            selectedProcess.accountingReceiptsMetrics
                              .not_reconciled_accounting_receipts_total
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#B42318",
                        }}
                      >
                        Diferencia balance inicial:{" "}
                        <NumericFormat
                          value={
                            selectedProcess.reconciliationProcess
                              .start_balance -
                            selectedProcess.reconciliationProcess
                              .erp_start_balance
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix={"$"}
                        />
                      </Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            <div style={styles.columnsContainer}>
              <BankMovements
                bankMovements={bankMovements}
                selectedMovement={selectedMovement}
                setSelectedMovement={setSelectedMovement}
                setPageIndex={setPageIndex}
                reconciliationOption={reconciliationOption}
                setReconciliationOption={setReconciliationOption}
                setStatusSelected={setStatusSelected}
                date={date}
                setDate={setDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                type={type}
                setType={setType}
                setTabSelected={setAccountingOption}
                setAccountingType={setAccountingType}
                reconciliationsCsvButton={reconciliationsCsvButton}
                perPageSelected={perPageSelected}
                setPerPageSelected={setPerPageSelected}
                setSelectedCostCenter={setSelectedCostCenter}
                payanaReceipts={payanaReceipts}
                setPayanaReceipts={setPayanaReceipts}
                resetAccounting={resetAccounting}
                setReconciledData={setReconciledData}
                amountSignSelected={amountSignSelected}
                setAmountSignSelected={setAmountSignSelected}
                totalPages={totalPages}
                pageIndex={pageIndex}
                pageLoading={pageLoading}
                setPageLoading={setPageLoading}
                setMovementId={setMovementId}
                selectedBank={selectedBank}
                setCoincidences={setCoincidences}
                setItems={setItems}
                setLoadingSupplierItems={setLoadingSupplierItems}
                setPayanaMovement={setPayanaMovement}
                accountingOption={accountingOption}
                description={description}
                setDescription={setDescription}
                refreshBankMovementsTable={refreshBankMovementsTable}
                isListLoading={isListLoading}
                setIsListLoading={setIsListLoading}
              />
              <Accounting
                accountingOption={accountingOption}
                setAccountingOption={setAccountingOption}
                selectedMovement={selectedMovement}
                searchOptions={searchOptions}
                handleSearchChange={handleSearchChange}
                prevItems={items}
                costCenters={costCenters}
                selectedCostCenter={selectedCostCenter}
                setSelectedCostCenter={setSelectedCostCenter}
                documentTypes={documentTypes}
                paymentMethods={paymentMethods}
                beneficiaryIdSelected={beneficiaryIdSelected}
                accountingType={accountingType}
                setAccountingType={setAccountingType}
                selectedDocumentType={selectedDocumentType}
                setSelectedDocumentType={setSelectedDocumentType}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                getSearchOptions={getSearchOptions}
                loadingSupplierItems={loadingSupplierItems}
                setLoadingSupplierItems={setLoadingSupplierItems}
                getPaymentMethodOptions={getPaymentMethodOptions}
                comment={comment}
                setComment={setComment}
                reconciledData={reconciledData}
                setIsMarkAsReconciledModalVisible={
                  setIsMarkAsReconciledModalVisible
                }
                setReconciliationOption={setReconciliationOption}
                setStatusSelected={setStatusSelected}
                resetAccounting={resetAccounting}
                setSelectedMovement={setSelectedMovement}
                showSiigoIntermittencies={showSiigoIntermittencies}
                setDocumentTypes={setDocumentTypes}
                accountingAccountOptions={accountingAccountOptions}
                setAccountingAccountOptions={setAccountingAccountOptions}
                payanaReceipts={payanaReceipts}
                movementId={movementId}
                coincidences={coincidences}
                setCoincidences={setCoincidences}
                selectedProcessId={selectedProcessId}
                refreshBankMovementsTable={refreshBankMovementsTable}
                setIsPartiallyReconciledDetailModalVisible={
                  setIsPartiallyReconciledDetailModalVisible
                }
                setIsReconcileFiltersModalVisible={
                  setIsReconcileFiltersModalVisible
                }
                setUnreconcileModalVisible={setUnreconcileModalVisible}
                setUnreconcileSet={setUnreconcileSet}
                refreshPayanaMovementReceipts={refreshPayanaMovementReceipts}
              />
            </div>
          </div>
        ) : (
          <>
            <div style={styles.titleContainer}>
              <Typography sx={styles.title} noWrap>
                Conciliaciones
              </Typography>
              <ButtonWithIcon
                action={() => {
                  setShowNewReconciliation(true);
                }}
                buttonLabel={"Conciliar nuevo banco"}
                width={"210px"}
              />
            </div>
            <div style={styles.titleContainer}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#344054",
                }}
                noWrap
              >
                Elige la cuenta a conciliar
              </Typography>
            </div>
            <div style={styles.pageContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "8px",
                  width: "100%",
                }}
              >
                {activeProcesses.map((process) => renderProcess(process))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
