const removeDebtsNotSelected = (selectedRowsIds) => {
  return ({ debts, service }) => ({
    debts: debts.filter((debt) => selectedRowsIds.includes(debt.debtId)),
    service,
  });
};

const objectsWithoutDebts = ({ debts }) => debts.length > 0;

const renameFields = ({ service, debts }) => {
  return {
    serviceCompany: {
      companyCode: service.companyCode,
      companyName: service.companyName,
      identifierValue: debts.at(0).identifierValue,
      modalityId: service.modalities.at(0).modalityId,
      modalityType: service.modalities.at(0).modalityType,
      modalityDescription: service.modalities.at(0).modalityDescription,
      identifierName: service.modalities.at(0).queryData.at(0).identifierName,
    },
    debts,
  };
};

export const getPayloadToCreateInvoicesFromDebts = (debts, selectedRowsIds) => {
  return {
    debts: debts
      .map(removeDebtsNotSelected(selectedRowsIds))
      .filter(objectsWithoutDebts)
      .map(renameFields),
  };
};
