import { useContext, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Badge,
  styled,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { slide as BurgerMenu } from "react-burger-menu";
import { styles } from "../styles";
import {
  CollectionsMobileIcon,
  EmptyProfile,
  ExternalLinkIcon,
  HelpCircleIcon,
  LogoutIcon,
  MenuIcon,
  MiniLogoutIcon,
  PayanaLogo,
  PaymentsIcon,
  PayrollIcon,
  ServiceIcon,
  SettingsIcon,
} from "../../../../assets";
import { UserContext } from "../../../../contexts";
import { Box } from "@mui/system";
import {
  getFromLocalStorage,
  logOut,
  saveInLocalStorage,
} from "../../../../utils";
import { CustomLink } from "../../components";
import { useChat } from "../../../../hooks";
import { AccountItem } from "./AccountItem";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 64;

export const AccountMenu = () => {
  const [menuOpen, setMenuOpen] = useState(null);
  const { currentUser, currentCompany } = useContext(UserContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { chatHidden } = useChat();
  const openMenu = getFromLocalStorage("openMenu") || false;

  const open = Boolean(menuOpen);

  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
    !openMenu && saveInLocalStorage("openMenu", true);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleLogout = () => {
    chatHidden();
    logOut();
    window.location = "/login";
  };

  const menuOptions = [
    {
      title: "Configuración",
      icon: (
        <SettingsIcon
          color="#475467"
          height={16}
          width={16}
          style={styles.iconMargin}
        />
      ),
      action: () => {
        handleClose();
        return navigate("/settings", { replace: true });
      },
    },
    {
      title: "FAQS",
      icon: <HelpCircleIcon style={styles.iconMargin} />,
      endIcon: <ExternalLinkIcon style={styles.iconMargin} />,
      action: () => {
        window.open("https://intercom.help/payana-ayuda/es/", "_blank");
      },
    },
    {
      title: "Cerrar sesión",
      icon: <MiniLogoutIcon style={styles.iconMargin} />,
      action: handleLogout,
    },
  ];

  const mobilePaymentsMenuOptions = [
    {
      title: "Cuentas por pagar",
      action: "/payments/invoices",
    },
    {
      title: "Agenda",
      action: "/contacts/suppliers",
    },
  ];

  const mobilePayrollMenuOptions = [
    {
      title: "Pago de nómina",
      action: "/payroll/salaries",
    },
    {
      title: "Empleados",
      action: "/contacts/employees",
    },
  ];

  const mobileCollectionsMenuOptions = [
    {
      title: "Cuentas por cobrar",
      action: "/collections/index",
    },
  ];

  mobileCollectionsMenuOptions.push({
    title: "Agenda",
    action: "/contacts/customers",
  });

  const mobileServicesMenuOptions = [
    {
      title: "Cuentas por pagar",
      action: "/services/payments",
    },
    {
      title: "Lotes",
      action: "/services/pay-later",
    },
    {
      title: "Agenda",
      action: "/contacts/services",
    },
  ];

  const mobileBOMenuOptions = [
    {
      title: "Soportes",
      action: "backoffice-receipts",
    },
    {
      title: "Soportes-Servicios",
      action: "backoffice-receipts-services",
    },
  ];

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      padding: "0 4px",
      backgroundColor: "red",
      height: "15px",
      minWidth: "15px",
      display: openMenu ? "none" : "block",
    },
  }));

  const accountItems = [
    {
      label: "Proveedores",
      icon: <PaymentsIcon />,
      menuOptions: mobilePaymentsMenuOptions,
    },
    {
      label: "Nómina",
      icon: <PayrollIcon />,
      menuOptions: mobilePayrollMenuOptions,
    },
    {
      label: "Cobros",
      icon: <CollectionsMobileIcon />,
      menuOptions: mobileCollectionsMenuOptions,
    },
    {
      label: "Servicios",
      icon: <ServiceIcon />,
      menuOptions: mobileServicesMenuOptions,
    },
  ];

  const initials = (name) => {
    return name
      ?.match(/\b(\w)/g)
      ?.join("")
      ?.toUpperCase()
      ?.slice(0, 2);
  };

  return (
    <div className="navDiv">
      <Button
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {isMobile ? (
          <div style={{ width: 20 }}>
            <MenuIcon />
          </div>
        ) : (
          <>
            <StyledBadge color="primary" overlap="circular" badgeContent="">
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "150px",
                  backgroundColor: "#D0D5DD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#344054",
                  }}
                >
                  {initials(currentUser.name)}
                </Typography>
              </div>
            </StyledBadge>
          </>
        )}
      </Button>

      {isMobile ? (
        <BurgerMenu
          right
          isOpen={menuOpen}
          onClose={handleClose}
          pageWrapId={"page-wrap"}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "white",
              padding: "16px",
              width: "100%",
            }}
          >
            <PayanaLogo />
          </div>
          {currentUser.backoffice_user ? (
            <div style={{ paddingLeft: "24px" }}>
              {mobileBOMenuOptions?.map((menuOption) => (
                <CustomLink
                  key={menuOption.title}
                  onClick={handleClose}
                  to={menuOption.action}
                >
                  {menuOption.title}
                </CustomLink>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: "80px" }}>
              {accountItems?.map((accountItem) => (
                <AccountItem
                  label={accountItem.label}
                  icon={accountItem.icon}
                  menuOptions={accountItem.menuOptions}
                  handleClose={handleClose}
                />
              ))}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
              height: "64px",
              alignItems: "center",
              marginLeft: "16px",
              marginRight: "16px",
              justifyContent: "space-between",
              borderTop: "1px solid #EAECF0",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {currentCompany?.file_path_logo ? (
                <img
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                  }}
                  src={currentCompany?.file_path_logo}
                  alt="companyLogo"
                />
              ) : (
                <EmptyProfile />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "16px",
                }}
              >
                <Typography sx={styles.companyName}>
                  {currentCompany?.name?.toUpperCase() ||
                    currentUser.name?.toUpperCase()}
                </Typography>
                <Typography sx={styles.email}>{currentUser.email}</Typography>
              </div>
            </Box>
            <IconButton onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </div>
        </BurgerMenu>
      ) : (
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={menuOpen}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                minWidth: "243px",
                borderRadius: "8px",
                padding: "8px",
                gap: "8px",
              },
            },
          }}
        >
          <Box pl={2} pr={1} py={1}>
            <Typography sx={styles.accountTitle}>{currentUser.name}</Typography>
            <div style={styles.menuContainer}>
              <div style={styles.menuIconText}>
                <Typography sx={styles.email}>{currentUser.email}</Typography>
              </div>
            </div>
          </Box>
          <Divider style={styles.divider} />
          {menuOptions?.map((menuOption) => (
            <MenuItem
              sx={styles.accountOptions}
              key={menuOption.title}
              onClick={menuOption.action}
            >
              <div style={styles.menuContainer}>
                <div style={styles.menuIconText}>
                  {menuOption.icon}
                  {menuOption.title}
                </div>
                {menuOption.endIcon && menuOption.endIcon}
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};
