import React from "react";
import { Dropdown } from "react-bootstrap";
import { ArrowIcon, ExcelIcon } from "../../../assets";
import { styles } from "../styles";
import { NewButton } from "../../../commons/buttons";
import { Divider } from "@mui/material";

export const NewSupplierDropdown = ({
  openUploadFileModal,
  openEmptySupplierDrawer,
  openEmptyServiceDrawer,
  type,
}) => {
  return (
    <Dropdown>
      <NewButton text="Nuevo" />
      <Dropdown.Menu style={{ padding: "0px" }}>
        <Dropdown.Item
          style={styles.newDropdownPadding}
          onClick={openEmptySupplierDrawer}
        >
          <div style={styles.dropdownItemContainer}>
            <span style={styles.dropdownItemIconContainer}>
              <ArrowIcon />
            </span>
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Crear proveedor</p>
            </div>
          </div>
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          style={styles.newDropdownPadding}
          onClick={openUploadFileModal}
        >
          <div style={styles.dropdownItemContainer}>
            <span style={styles.dropdownItemIconContainer}>
              <ExcelIcon width="24" height="24" />
            </span>
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Archivo Excel</p>
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
