import React from "react";
import { InfoIcon } from "assets";

export const ACHInfoDisclaimer = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "4px",
      }}
    >
      <InfoIcon color="#175CD3" width={16} height={16} />
      <p
        style={{
          color: "#175CD3",
          fontSize: "12px",
          marginTop: "-1px",
        }}
      >
        Debido al banco de destino, el dinero está sujeto al procesamiento ACH,
        lo que puede demorar entre 24 y 72 horas.
      </p>
    </div>
  );
};
