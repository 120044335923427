import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { CloseButton, Modal } from "react-bootstrap";
import { GetUserCompanies } from "services";
import { UserContext } from "contexts";
import {
  CreateError,
  CreateSuccess,
  EmptyState,
  ManagerConfirm,
  ManagerForm,
  MultiAccount,
  NewCompanyForm,
} from "./components";
import { CustomSkeleton } from "commons/components";
import { ChevronLeftIcon } from "assets";

export const CompanySwitchModal = ({ onClose, visible }) => {
  const [companies, setCompanies] = useState([]);
  const [content, setContent] = useState("newCompany");
  const [companyData, setCompanyData] = useState(null);
  const [createdCompany, setCreatedCompany] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { currentUserIsLoggedIn } = useContext(UserContext);

  // useEffect(() => {
  //   currentUserIsLoggedIn &&
  //     GetUserCompanies().then((res) => {
  //       setCompanies(res);
  //       res.length > 1 ? setContent("companies") : setContent("empty");
  //     });
  // }, [currentUserIsLoggedIn, visible]);

  const handleNewCompany = () => {
    setContent("newCompany");
  };

  const handleClose = () => {
    setContent("newCompany");
    setCompanyData(null);
    onClose();
  };

  const renderContent = {
    loading: <CustomSkeleton />,
    companies: (
      <MultiAccount
        companies={companies}
        onClick={handleNewCompany}
        onClose={handleClose}
      />
    ),
    empty: <EmptyState onClick={handleNewCompany} />,
    newCompany: (
      <NewCompanyForm
        setContent={setContent}
        setCreatedCompany={setCreatedCompany}
        setCompanyData={setCompanyData}
        companyData={companyData}
      />
    ),
    managerForm: (
      <ManagerForm
        setContent={setContent}
        setCompanyData={setCompanyData}
        companyData={companyData}
      />
    ),
    managerSuccess: (
      <CreateSuccess
        createdCompany={createdCompany}
        onClose={handleClose}
        isManager={true}
      />
    ),
    managerConfirm: (
      <ManagerConfirm
        companyData={companyData}
        onClose={handleClose}
        setContent={setContent}
        setCompanyData={setCompanyData}
        setCreatedCompany={setCreatedCompany}
      />
    ),
    createNoManagerSuccess: (
      <CreateSuccess
        createdCompany={createdCompany}
        onClose={handleClose}
        isManager={false}
        companyData={companyData}
      />
    ),
    error: <CreateError cancel={handleNewCompany} />,
  };

  const width = {
    loading: "552px",
    companies: "782px",
    empty: "552px",
    newCompany: "552px",
    managerForm: "552px",
    managerSuccess: "400px",
    createNoManagerSuccess: "400px",
    managerConfirm: "400px",
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <div class="outer">
        <div class="inner">
          <Box
            style={{
              width: isMobile ? "330px" : width[content],
              maxHeight: "680px",
            }}
            className="company-switch-modal"
          >
            <Modal.Header
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent:
                  content === "newCompany" || content === "managerForm"
                    ? "space-between"
                    : "flex-end",
                paddingBottom: 0,
              }}
              className="modal-header--sticky"
            >
              {content === "managerForm" && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    content === "newCompany"
                      ? companies.length > 1
                        ? setContent("companies")
                        : setContent("empty")
                      : setContent("newCompany")
                  }
                >
                  <ChevronLeftIcon />
                </div>
              )}
              <CloseButton onClick={() => handleClose()} />
            </Modal.Header>
            <div
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingBottom: "12px",
              }}
            >
              <Modal.Body>{renderContent[content]}</Modal.Body>
            </div>
          </Box>
        </div>
      </div>
    </Modal>
  );
};
