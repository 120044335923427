import React from "react";
import { Tooltip as MuiTooltip, styled, tooltipClasses } from "@mui/material";

export const Tooltip = styled(
  ({ offset = -10, className, arrow = false, onClick, ...props }) => (
    <MuiTooltip
      {...props}
      arrow={arrow}
      classes={{ popper: className }}
      onClick={onClick}
      componentsProps={{
        tooltip: {
          onClick: onClick,
          sx: { cursor: onClick ? "pointer" : "default" },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, offset],
              },
            },
          ],
        },
      }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#101828",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#101828",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: "12px",
    maxWidth: "250px",
  },
}));
