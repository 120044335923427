import { CrownIcon } from "assets";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import React from "react";

export const SubscriptionButton = ({
  subscription,
  isDisable,
  text,
  action,
  width,
  style,
  buttonType = "primary",
  ...props
}) => {
  const handleAction = (callback) => {
    if (subscription) {
      subscription.handleActionSubscription(callback);
    } else {
      callback();
    }
  };

  const button = {
    primary: (
      <PrimaryButton
        icon={
          subscription?.showIcon && <CrownIcon size="small" fill="#EFA83C" />
        }
        isDisable={isDisable}
        text={text}
        action={() => handleAction(action)}
        width={width}
        style={style}
        {...props}
      />
    ),
    secondary: (
      <SecondaryButton
        icon={
          subscription?.showIcon && <CrownIcon size="small" fill="#EFA83C" />
        }
        isDisable={isDisable}
        text={text}
        action={() => handleAction(action)}
        width={width}
        style={style}
        {...props}
      />
    ),
  };

  return button[buttonType];
};
