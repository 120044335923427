import React from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { styles } from "../styles";

export const BankMovementsFilters = ({
  setPageIndex,
  setPerPageSelected,
  description,
  setDescription,
  setIsFiltersModalVisible,
}) => {
  return (
    <div style={styles.filtersContainer}>
      <div style={styles.filtersRow}>
        <TextField
          size="small"
          placeholder="Buscar movimiento"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{
            width: "300px",
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E4E7EC",
              },
              "&:hover fieldset": {
                borderColor: "#E4E7EC",
              },
            },
          }}
          InputProps={{
            endAdornment: description && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setDescription("")}
                  edge="end"
                  size="small"
                >
                  <ClearIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          onClick={() => setIsFiltersModalVisible(true)}
          sx={{
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <FilterListIcon sx={{ fontSize: "22px" }} />
        </IconButton>
      </div>
      <div className="paginationNav">
        <select
          className="page-link"
          style={styles.paginationPerPage}
          onChange={(e) => {
            setPerPageSelected(e.target.value);
            setPageIndex(1);
          }}
        >
          {[50, 100, 150, 200].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
