import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#5925DC",
    },
    secondary: {
      main: "#F4F3FF",
    },
    tertiary: {
      main: "#EAECF0",
    },
    error: {
      main: red.A400,
      icon: "#EE0004",
    },
    background: {
      default: "#F6F7F9",
      paper: "#FFFFFF",
      gray: "#C9C9C9",
      grayLight: "#DCE1E9",
    },
    black: {
      textBlack: "#353535",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    weight: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
  },
  breakpoints: {
    payanaTablet1280x800: 1280,
    payanaTablet1024x768: 1025,
    payanaTablet870x620: 880,
    payanaTablet8: 855,
    payanaTablet700: 700,
  },
});
